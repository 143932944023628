<template>
  <teleport to="#modal_container">
    <div     :style="{
      gap: gap ? gap : '15px',
      padding: padding ? padding : '0px',
      borderRadius: borderRadius ? borderRadius : '10px',

    }" class="modal-container">
      <div class="d-flex flex-column align-center w-100">
        <v-icon
          v-if="icon"
          :size="iconSize"
          :color="iconColor"
          :icon="icon"
        ></v-icon>
        <div v-if="title" class="title">{{ title }}</div>
      </div>
      <slot></slot>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    iconSize: {
      type: String,
      default: "40px",
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
    },
    gap: {
      type: String,
    },
    padding: {
      type: String,
    },
    borderRadius: {
      type: String,
    },
    paddingTop: {
      type: String,
    },
    paddingBottom: {
      type: String,
    },
    paddingLeft: {
      type: String,
    },
    paddingRight: {
      type: String,
    },
    maxWidth: {
      type: String,
      default: "unset",
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  z-index: 4;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: white;
  display: flex;
  border-radius: 30px;
  flex-direction: column;
  align-items: center;
  max-width: v-bind(maxWidth);
  /* box-shadow: 0px 11px 15px -7px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0px 24px 38px 3px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0px 9px 46px 8px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12)); */
  .title {
    font-size: 22px;
    font-weight: 700;
    color: #434343;
  }
}

@media (max-width: 1024px) {
  .modal-container {
    width: 95%;
  }
}
</style>
