<template>
  <div class="warper">
    <div
      v-for="action in ActionsList"
      :key="action"
      :class="[selectedAction === action.name ? 'active' : '', mode]"
      class="actionButton"
      @click="$emit('selectAction', action)"
    >
      {{ action.name }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  emits: ["selectAction"],
  data() {
    return {}
  },
  props: {
    ActionsList: {
      type: Array,
      required: true,
    },
    selectedAction: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.warper {
  font-size: 18px;
  width: fit-content;
  border: 1px solid $borderColor;
  border-radius: 50px;
  display: flex;
  gap: 20px;
  padding: 5px 10px;
  text-align: center;
}
.actionButton {
  cursor: pointer;
  background: white;
  color: $statusListColor;
  border-radius: 20px;
  padding: 5px 10px;
  &.active {
    color: white;
    box-shadow: 0px 0px 8px 0px #00000025;
  }
  &.active.controlPanel {
    background: $controlPanelMainColor;
  }
  &.active.customers {
    background: $customerMainColor;
  }
  &.active.orders {
    background: $ordersMainColor;
  }
}
</style>
