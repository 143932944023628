<template>
  <div
    class="customer-table-header d-flex align-start"
    :style="{ width: isMobile ? 'fit-content' : '100%' }"
    :class="{ visible: showHeader }"
  >
    <div
      :style="{ width: headerWidth }"
      class="d-flex align-center justify-space-between pt-2 pb-0"
    >
      <TableInfoLine
        v-for="(tableInfoLineHeader, index) in tableInfoLinesHeader"
        class="table-line"
        :key="index"
        :title="tableInfoLineHeader"
        titleLabelColor="#ffffff"
      />
    </div>
  </div>
</template>

<script>
import TableInfoLine from "./TableInfoLine.vue";

export default {
  components: { TableInfoLine },
  props: {
    headerWidth: {
      type: String,
      default: "100%",
    },
    tableInfoLinesHeader: {
      type: Array,
      default: () => [],
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.customer-table-header {
  background: #801612e0;
  position: sticky;
  border-top: 1px solid rgb(228, 228, 228);
  box-shadow: 0px 10px 15px -14px rgba(0, 0, 0, 0.1);
  top: 0px;
  right: 0;
  z-index: 1;
  width: 100%;
  padding-right: 50px;
  // transition: opacity 0.3s ease;
  // opacity: 0;
  // visibility: hidden;
}

.customer-table-header.visible {
  opacity: 1;
  visibility: visible;
}

.table-line {
  flex: 1;
  min-width: 120px;
  max-width: 140px;
  height: 40px;
}

@media only screen and (max-width: 1440px) {
  .customer-table-header {
    width: fit-content !important;
    top: -1px;
  }

  .table-line {
    min-width: 200px;
  }
}
</style>
