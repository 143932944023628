import PartService from "../../services/PartService";

export default {
  namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
  state() {
    return {
      parts: [],
      onlyForCust: false,
    };
  },
  mutations: {
    restQuantityByIndex(state, productIndex) {
      state.parts[productIndex].quant = 0;
    },
    updatePart(state, obj) {
      var { productIndex, part } = obj;
      console.log(part);
      state.parts[productIndex] = { ...part };
    },
    setParts(state, partsList) {
      state.parts = partsList;
    },
    toggleCustOnly(state, mode) {
      state.onlyForCust = mode;
    },
    setFavorite(state, data) {
      let index = state.parts.findIndex((part) => part.part === data.part);
      state.parts[index].CustParts[0].Star = data.mode;
    },
  },
  actions: {
    restAllQuantity(context, lines) {
      lines.forEach((line) => {
        var productIndex = context.getters.getPartIndexById(line.ordi);
        context.commit("restQuantityByIndex", productIndex);
      });
    },
    restQuantityByPart(context, part) {
      var productIndex = context.getters.getPartIndexById(part.ordi);
      context.commit("restQuantityByIndex", productIndex);
    },
    updateProduct(context, obj) {
      var { product, quantity } = obj;
      product.quant = quantity;
      context.dispatch("updatePart", product);
      if (quantity) {
        context.dispatch("Orders/addProductToCart", product, { root: true });
      } else {
        context.dispatch("Orders/removeProductFromCart", product, {
          root: true,
        });
      }
    },
    updatePart(context, part) {
      var productIndex = context.getters.getPartIndexById(part.ordi);
      context.commit("updatePart", { productIndex, part });
    },
    toggleThis(context, mode) {
      context.commit("toggleCustOnly", mode);
    },

    async toggleFavorite(context, data) {
      context.dispatch("startLoading", null, { root: true });
      try {
        await PartService.starProduct(data);
      } catch (err) {
        console.log("error starting product:" + err);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: "ארעה שגיאה, מומלץ לרענן את הדף ולנסות שוב",
          },
          { root: true }
        );
      }
      context.commit("setFavorite", data);
      context.dispatch(
        "stopLoading",
        { status: "success", message: "עודכן במועדפים" },
        { root: true }
      );
    },
  },
  getters: {
    getPartIndexById: (state) => (ordi) => {
      return state.parts.findIndex((part) => part.ordi === ordi);
    },
    getPartLengths: (state) => (partId) => {
      //console.log('part iddddd: ', partId);
      console.log(partId);
      return state.onlyForCust;
    },
    getParts(state) {
      return state.parts;
    },
    getonlyForCust(state) {
      return state.onlyForCust;
    },
    getCategories(state) {
      let Categories = {};

      state.parts.forEach((part) => {
        if (part.Family) {
          if (typeof Categories[part.familyname] === "undefined") {
            Categories[part.familyname] = part.Family;
            Categories[part.familyname].counter = 0;
            Categories[part.familyname].custCounter = 0;
          }
          Categories[part.familyname].counter++;
          if (part.CustParts.length > 0)
            Categories[part.familyname].custCounter++;
        }
      });
      return Categories;
    },
  },
};
