<template>
  <div class="grid pa-5">
    <!-- <base-search class="full-card" :is-search-header="true" /> -->
    <card-component
      v-if="getUserRole?.agentapp"
      @click="goToProductsSearch"
      class="full-card new-order"
      :title="$t('Header.controlPanel')"
      :subtitle="$t('Header.pendingItems')"
      :value="cartTotal"
      icon="mdi-face-agent"
    />
    <card-component
      @click="$router.push('/orders/allorders')"
      class="orders"
      :title="$t('Header.myorders')"
      :subtitle="$t('Header.activeorders')"
      :value="openOrders"
      icon="mdi-clipboard-text-clock"
    />
    <card-component
      @click="$router.push('/customers/allcustomers')"
      class="customers"
      :title="$t('Header.cunstomers')"
      :subtitle="$t('Header.activeCustomers')"
      :value="activeCustomers"
      icon="mdi-invoice-text-outline"
    />
    <card-component
      @click="$router.push('/agentRequests')"
      class="full-card new-order"
      :title="$t('CartWidget.title')"
      :subtitle="$t('CartWidget.waitingAgentRequest')"
      :value="openAgentRequests"
      icon="mdi-bell"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import BaseSearch from "../menus/BaseSearch.vue";
import CardComponent from "../helpers/CardComponent.vue";

export default {
  components: {
    // BaseSearch,
    CardComponent,
  },
  methods: {
    goToProductsSearch() {
      this.$store.commit("setIsShowDesktopHome", true);
    },
  },
  computed: {
    ...mapGetters({
      cartTotal: "Orders/getCartTotal",
      openOrders: "Orders/getOpenOrders",
      activeCustomers: "Customers/getActiveCustomers",
      openAgentRequests: "AgentRequests/getOpenAgentRequests",
      getUserRole: "AuthModule/getUserRole",
    }),
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 20px;
  max-width: 500px;
  margin: auto;
}
.full-card {
  grid-column-start: 1;
  grid-column-end: 3;
}
.new-order {
  background: $controlPanelNavCardColor;
}
.customers {
  background: $customersNavCardColor;
}
.orders {
  background: $ordersNavCardColor;
}
</style>
