<template>
  <div class="cart-info-container">
    <div class="cart-info">
      <span class="title">מוצרים</span>
      <span class="number">{{
        this.$store.getters["Orders/getCartOrderLinesNumber"]
      }}</span>
    </div>

    <div class="cart-info">
      <span class="title">כמות</span>
      <span class="number">{{
        this.$store.getters["Orders/getCartTotal"]
      }}</span>
    </div>

    <div class="cart-info">
      <span class="title">סה"כ לתשלום</span>
      <span class="number highlithed"
        ><span style="font-size: 16px">₪</span
        >{{ this.$store.getters["Orders/getCartTotalPrice"] }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "CartSummary",
};
</script>

<style scoped lang="scss">
.cart-info-container {
  display: flex;
  align-items: center;
  gap: 14px;
}

.cart-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2px;

  .title,
  .number {
    color: $primaryColorlight;
  }
  .title {
    font-size: $extraSmallTextSize;
  }

  .number {
    font-size: 22px;
    font-weight: bold;
    margin-top: -5px;
  }

  .number.highlithed {
    color: $primaryColorlight;
  }
}
</style>
