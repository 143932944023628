<template>
  <li style="max-width: fit-content">
    <InnerBox :title="invoice.doctype">
      <template #content>
        <div class="invoice-summary d-flex flex-column ga-2">
          <div class="d-flex justify-space-between">
            <label>מס' {{ invoice.doctype }}:</label>
            <span>{{ invoice.docno }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <label>תאריך:</label>
            <span>{{ getDate }}</span>
          </div>
          <v-btn
            @click="$emit('download-invoice', invoice.docId)"
            class="mt-2"
            variant="tonal"
          >
            הורדת התעודה
          </v-btn>
        </div>
      </template>
    </InnerBox>
  </li>
</template>

<script>
import InnerBox from "../UI/InnerBox.vue";
export default {
  name: "BaseInvoice",
  emits: ["download-invoice"],
  components: {
    InnerBox,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isInvoiceExpanded: true,
    };
  },
  computed: {
    getDate() {
      return this.invoice.curdate;
    },
  },
  methods: {
    expandInvoice() {
      this.isInvoiceExpanded = !this.isInvoiceExpanded;
    },
  },
};
</script>

<style scoped lang="scss">
.invoice-title {
  display: flex;
}
.base-invoice {
  font-size: $mediumTextSize;
  color: #c4c4c4;
  margin: 5px 0px;
  border-radius: 20px;
  background: #3f3f4d;
  padding: 10px 20px;
}
.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-active {
  display: none !important;
}
.expand-icon {
  opacity: 0.5;
}
.invoice-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  .key {
    font-size: $smallTextSize;
    color: #c4c4c4;
  }

  .value {
    font-size: $mediumTextSize;
    color: $primaryTextColor;
  }

  .info {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  .invoice-info {
    flex-direction: column;

    .info {
      flex-direction: row;
      justify-content: space-between;
      gap: 5px;
      width: 100%;
    }

    .download-invoice-btn {
      margin-top: 10px;
    }
  }
}
.selected {
  color: $primaryTextColor;
  text-decoration: underline;
}

.invoice-type {
  cursor: pointer;
}
.invoice-summary {
  max-width: 320px;

  label {
    font-size: $mediumTextSize;
    font-weight: 400;
    line-height: 21.33px;
    text-align: right;
    color: $orderLineColor;
  }

  span {
    font-size: $mediumTextSize;
    font-weight: 700;
    line-height: 21.33px;
    text-align: right;
    color: #434343;
  }
}
</style>
