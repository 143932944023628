<template>
  <teleport to="#modal_backdrop">
    <div
      @click="this.$emit('close-model')"
      class="backdrop"
      :style="{ 'background-color': bgColor }"
    />
  </teleport>
</template>

<script>
export default {
  emits: ["close-model"],
  props: {
    bgColor: {
      type: String,
    },
  },
};
</script>
<style scoped>
.backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 4;
}
</style>
