<template>
  <div>
    <TableWrapper
      mode="order"
      :sumCount="ordersList.length"
      title="הזמנות שבוצעו"
      icon="mdi mdi-text-box-check"
    >
      <div class="orders-table-content" @scroll="handleScroll">
        <StickyTableHeader
          headerWidth="88%"
          :tableInfoLinesHeader="ordersTableInfoLinesHeader"
          :showHeader="showHeader"
          :isMobile="isMobile"
        />
        <div class="d-flex flex-column ga-3 pa-5">
          <OrderTableLine
            v-for="(order, index) in ordersList"
            :key="order.ord"
            :order="order"
            :orderId="order.ord"
            :currentExpandBtn="currentExpandBtn"
            :tableLineBackground="
              (index + 1) % 2 !== 0 ? '#ffefdb' : 'transparent'
            "
            @close-expand="setCurrentExpandBtn"
            @set-current-expand-btn="setCurrentExpandBtn"
          />
        </div>
      </div>
    </TableWrapper>
    <v-pagination
      v-model="page"
      :active-color="'blue'"
      :length="numberOfPages"
      :total-visible="7"
    ></v-pagination>
  </div>
</template>

<script>
import OrderTableLine from "./OrderTableLine.vue";
import TableWrapper from "./TableWrapper.vue";
import StickyTableHeader from "./StickyTableHeader.vue";

export default {
  components: { OrderTableLine, TableWrapper, StickyTableHeader },
  props: ["ordersList", "count"],
  data() {
    return {
      currentExpandBtn: 0,
      page: 1,
      showHeader: false,
      ordersTableInfoLinesHeader: [
        this.$t(`orders.table.columns.customer`),
        this.$t(`orders.table.columns.order`),
        this.$t(`orders.table.columns.distLine`),
        this.$t(`orders.table.columns.distLineDays`),
        this.$t(`orders.table.columns.orderDate`),
        this.$t(`orders.table.columns.agent`),
        "קרטונים ומחיר",
      ],
    };
  },
  watch: {
    page() {
      var query = {
        ...this.$route.query,
        page: this.page,
      };

      this.$router.push({ path: this.$route.path, query: query });
    },
    "$route.query": {
      immediate: true,
      handler() {
        this.page = 1;
      },
    },
    "$route.query.page": {
      immediate: true,
      handler() {
        console.log(typeof this.$route.query.page);
        if (this.$route.query.page) {
          if (this.$route.query.page > this.numberOfPages) {
            this.page = this.numberOfPages;
          } else this.page = Number(this.$route.query.page);
        }
      },
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.count / 30);
    },
    currentRoutePath() {
      return this.$route.path;
    },
    number() {
      return this.page;
    },
    currentRouteParams() {
      return this.$route.params;
    },
  },
  methods: {
    handleScroll(event) {
      this.showHeader = event.target.scrollTop > 0;
    },
    setCurrentExpandBtn(orderId) {
      this.currentExpandBtn = orderId;
    },
  },
};
</script>

<style lang="scss">
.v-pagination__list {
  overflow-y: scroll !important;
}
</style>

<style scope lang="scss">
.orders-table-content {
  position: relative;
  height: calc(100vh - 200px) !important;
  overflow-y: auto;
}
</style>
