import { en } from "vuetify/lib/locale";

export default {
  direction: "ltr",
  ...en,
  LoginPage: {
    loginFormTitle: "Log In to your Nirotek account",
    userInputPlaceHolder: "Phone Number / mail",
    enterPhoneNumberOrMail: "enter phone number / mail",
    enterCode: "enter code you get from phone / mail",
    missingPhoneOrMail: "missing phone number / mail",
    errorLoginServerSide: "Somthing went wrong while login",
    emptyOtpInput: "otp field can not be empty",
    mandatoryFieldError: "mandatory field",
    welcome:
      "Welcome to the fast and convenient service experience only for googly customers.",
    passwordPlaceholder: "Password..",
    loginButton: "Get a code to enter",
    eneter: "enter",
    forgetPassButton: "Forgot password?",
  },

  Header: {
    neworder: "New Order",
    catitems: "Items in Cart",
    myorders: "My Orders",
    activeorders: "Active Orders",
    finances: "Invoces",
    activefinances: "Open Invoces",
    invoicesAndReturns: "Invoces and returns",
    activeReturns: "Open Returns",
    Returns: "Returns",
    tabFinances: "Invoces",
    hello: "hello",
    logout: "logout",
    nirotekwebsite: "Googly WebSite",
    logourl: "/images/logoen.png",
  },

  Footer: {
    contactTitle: "For help",
    safetyText: "The site is secured using SSL",
  },

  ProductWidget: {
    SKU: "SKU",
    description: "Description",
    partFamily: "part Family",
    basePrice: "Base Price",
    discount: "Discount",
    finalPrice: "Final Price",
    orderNumber: "Order",
    reference: "Reference",
    quantity: "Quantity",
    sumPrice: "Sum",
  },

  Products: {
    clearAll: "Clear all",
    overFiftyProducts:
      "Only initial 50 results will be displayed, filters can be used for additional results",
    moreProductsAvailable:
      "Additional products can be found when using filters at the top of the page.",
    noProductsWereFound: "No products were found",
    productItem: {
      sku: "sku",
      category: "category",
      description: "description",
      addition: "addition",
      AvailableInStock: "available in stock",
      outOfStock: "out of stock",
      unitOfSale: "unit of sale",
      width: "width",
      height: "height",
      length: "length",
      weight: "weight",
      status: "status",
      volume: "volume",
      moreInfo: "מידע נוסף למוצר",
    },
  },

  ProductModal: {
    alreadyInCart: "Product already in cart",
    notCustPart:
      "Please note that this product does not exist in your product group.",
    orderLine: " Order line",
    cancel: "Cancel",
    note: "Product note",
    save: "Add to cart",
    inbox: "To pack ?",
    quantity: "Quantity of sheets",
    gilyonLength: "Sheet length..",
    gilyonWidth: "Sheet width..",

    galilLength: "Sheet length in meter..",
    galilCutWidth: "Cutting width in cm..",
    total: "Cumulative overall width",
    cm: "Cm",
    validWidths: "possible widths of cylinders:",
    numOfCycles: "Number of Cycles",
    invalidTotalWidth:
      "The sum of the slice widths does not match the possible widths and therefore a reduction of ",
    unbox: "Not packed",
    boxed: "Packed",
    sheets: "Sheets",
    galilee: "Galilee",
    notvalidWidth: "Sum Not valid - max sum",
    numberCuts: "Width number",
    numberCycles: "Cycles number",
    phatAlert: "Must be coordinated with sales",
    duplicateCutsAlert: "You cannot repeat the width of a slice more than once",
    inboxAlert: "The final quantity of sheets will be confirmed by sales",
  },

  SingelDoc: {
    shippingCode: "Shipping Code",
    PurchaseOrderNumber: "Purchase doc number",
    contact: "Contact",
    status: "Status",
    contactTooltip:
      "You can change a contact person only after placing the order",
    Remarks: "Remarks",
    maxLetters: "The Max Letters is 120",
    docDetails: "Order details",
  },

  SingleOrder: {
    NewOrder: "New order",
    OrderNumber: "Order number",
    shippingCode: "Shipping Code",
    PurchaseOrderNumber: "Purchase order number",
    contact: "Contact",
    status: "Status",
    address: "Address",
    orderDetails: "Order details",
    Remarks: "Remarks",
    SendOrder: "Send Order",
    ProductionDate: "Production Date",
    Update: "Update Order",
    sent: "Sent",
    inpPreparation: "preparation",
    registered: "Registered",
    maxLetters: "The Max Letters is 120",
    ordersEmpty: "Empty order",
    savedBeforeSendOrder: "the order didnt save before Send",
    viewDeliveryDoc: "View certificate",
    contactTooltip:
      "You can change a contact person only after placing the order",
    orderSummaryTitle: "Order Summary",
    orderCountItems: "Number of items",
    priceBeforeTax: "Price before tax",
    priceIncludeTax: "Price include tax",
    tax: "Tax",
    finishEdit: "Finish edit",
    cancelEdit: "Cancel edit",
  },

  finances: {
    table: {
      accounting: {
        invoiceDate: "invoice date",
        maturityDate: "maturity date",
        backlogDays: "back log days",
        order: "order",
        yourOrder: "your Order",
        invoice: "invoice",
        typeOfMovement: "type of movement",
        details: "details",
        debit: "debit",
        sum: "sum",
        currency: "currency",
      },
      catalogue: {
        balanceDate: "balance date",
        valueDate: "value date",
        referenceTwo: "reference 2",
        details: "details",
        counterAccountDescription: "counter account description",
        noJournalMovement: "no journal movement",
        typeOfMovement: "type of movement",
        debit: "debit",
        credit: "credit",
        cumulativeBalance: "cumulative balance",
        currency: "currency",
      },
    },
  },
  SingelInvoice: {
    invoiceNumber: "Invoice",
    invoiceDetails: "Invoice details",
    refundRequest: "Create a return request",
    sendRefundRequest: "Send return request",
    cancelRefundRequest: "Cancel return request",
    printCopy: "Generating a copy of an invoice",
    addQuant: "Add quantity",
    refundReason: "Reason for return...",
    printModalTitle: "Your request has been successfully received",
    printModalContent:
      "The requested document will be sent to email in the next few minutes",
    printModalCloseBtn: "Close",
  },
  Categories: {
    langFieldName: "familyedes",
    myProducts: "My products",
    allProducts: "All products",
    title: "Product Categories",
    viewOrder: "View order »",
    favorites: "favorites",
    sticky: "Sticky",
  },

  BaseSearch: { search: "search", placeholder: "Search products by SKU" },

  sideBar: {
    Invoice: {
      title: "Invoice",
    },
    Order: {
      title: "Order",
    },
    Category: {
      title: "products",
    },
    orders: {
      orderStatus: "Orders",
      title: "My orders",
      showAll: "All Orders",
    },
    invoices: {
      title: "Invoices and refunds",
      showAll: "All Invoices",
      titleListOne: "Invoices and credits",
      titleListTwo: "Goods return requests",
    },
    finances: {
      title: "Finances",
      showAll: "All Finances",
    },

    cart: {
      title: "New Order",
    },
  },

  orders: {
    myOrders: "My orders",
    addressForDelivery: "Address for delivery",
    searchBySKU: "Search by SKU",
    sum: "Sum",
    status: "Status",
    orderDetails: "Order details",
    Remarks: "Remarks",
    SendOrder: "Send order",
    productionDate: "Production date",
    orderNumber: "Order number",
    deliveryCertificate: "Delivery certificate",
    DeliveryCertificatenNotProvided: "Delivery certificate not yet provided",
    dueDate: "Due date",
    reference: "your order",
  },
  invocies: {
    searchBySKU: "Search by SKU, Invoice number",
    docNumber: "Invoice number",
    productionDate: "Production date",
    sum: "Sum",
    status: "Status",
    dueDate: "Due date",
  },
  home: {
    welcome: "Welcome to Nirotak's ordering site",
    start: "You can start",
  },

  orderSuccModal: {
    title: "Order has been successfully received",
    orderNumber: "Order number",
    alertText:
      "The TAPI department may make further adjustments, we will update you if there is a change.",
    watchOrder: "View order",
    processingYourRequest: "Processing your request",
    continueOrdering: "Continue ordering",
  },

  downloadButtonWidget: {
    buttonText: "Download File",
  },

  filtersWidget: {
    dateLabel: "From Date",
    ordersAndInvoicesDateLabel: "Send Date",
  },

  CartWidget: {
    title: "Agent request management",
    emptyCartTitle: "Your cart is empty...",
    allRequests: "All Requests",
    startOrderBtn: "Start order",
    orderSummaryTitle: "Order summary",
  },
};
