<template>
  <v-snackbar :color="toastColor" v-model="isShowToast" multi-line>
    {{ toastMessage }}

    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="closeToast"> סגור </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  methods: {
    closeToast() {
      this.$store.commit("setIsShowToast", false);
    },
  },
  computed: {
    ...mapGetters({
      isShowToast: "isShowToast",
      toastMessage: "toastMessage",
      toastColor: "toastColor",
    }),
  },
};
</script>

<style>
</style>