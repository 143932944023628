<template>
  <modal-container
    :gap="'0px'"
    :borderRadius="'20px'"
    :padding="!getIsDesktop ? '30px 20px' : '50px 40px'"
  >
    <Backdrop
      v-if="openModal"
      @close-model="$emit('closeModal')"
      :bgColor="'#00000061'"
    >
    </Backdrop>
    <div class="content w-100">
      <div class="d-flex justify-space-between">
        <div class="title-wrapper">
          <!-- <SideBarTitle
            :title="$t('sideBar.invoices.title')"
            mode="customers"
            iconName="mdi mdi-briefcase-outline"
            :isSideBarTitleShown="true"
          /> -->
          <IconTitle
            iconName="mdi mdi-briefcase-outline"
            mode="customers"
            :title="$t('sideBar.invoices.title')"
            titleSize="30px"
          />
          <span class="font-18">{{ custDes }}</span>
        </div>
        <v-icon
          icon="mdi mdi-close-circle-outline"
          size="25"
          class="mb-2"
          color="#757575"
          @click="$emit('closeModal')"
        />
      </div>
      <div class="d-flex flex-column ga-10">
        <div class="table-actions-container ga-5">
          <controlActionsButtons
            v-if="false"
            :ActionsList="[
              { name: 'חש.פתוחות', path: '/' },
              { name: 'חש.סגורות', path: '/' },
            ]"
            :selectedAction="selectedAction"
            @selectAction="selectAction"
            mode="customers"
          />
          <BaseSearch
            @update-search-word="updateUserSearchInput"
            :searchword="userSearchInput"
            :placeholder="$t('BaseSearch.placeholder')"
            isLocalSearch
            isSearchHeader
            mode="customers"
          />
        </div>
        <div class="invoice-table">
          <InvoiceTable
            v-if="filteredInvoicesList.length > 0"
            :invoicesList="filteredInvoicesList"
          />
          <NotFound
            v-else
            :style="{ 'min-width': getIsDesktop ? '1200px' : '355px' }"
            title="לא נמצאו חשבוניות..."
          />
        </div>
      </div>
    </div>
  </modal-container>
</template>

<script>
import moment from "moment";
import ControlActionsButtons from "../../helpers/controlActionsButtons.vue";
import InvoiceTable from "../../helpers/InvoiceTable.vue";
import BaseSearch from "../../menus/BaseSearch.vue";
// import SideBarTitle from "../../menus/SideBarTitle.vue";
import Backdrop from "../backdrop.vue";
import ModalContainer from "./modal-container.vue";
import { mapGetters } from "vuex";
import NotFound from "../../helpers/NotFound.vue";
import IconTitle from "../../UI/IconTitle.vue";

export default {
  components: {
    ModalContainer,
    Backdrop,
    // SideBarTitle,
    ControlActionsButtons,
    BaseSearch,
    InvoiceTable,
    NotFound,
    IconTitle,
  },
  emits: ["closeModal"],
  data() {
    return {
      selectedAction: "חש.פתוחות",
      userSearchInput: "",
    };
  },
  props: {
    openModal: {
      type: Boolean,
      defult: false,
    },
    invoicesList: {
      type: Array,
    },
    custDes: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateUserSearchInput(value) {
      this.userSearchInput = value;
    },
    selectAction(action) {
      console.log(action.name);
      this.selectedAction = action.name;
    },
  },
  computed: {
    filteredInvoicesList() {
      return this.invoicesList.filter((invoice) => {
        let isFiltered = true;
        // switch (this.selectedAction) {
        //   case "חש.פתוחות":
        //     isFiltered = invoice.isInvoiceOpen;
        //     break;
        //   case "חש.סגורות":
        //     isFiltered = !invoice.isInvoiceOpen;
        //     break;
        // }
        if (this.userSearchInput.trim().length > 0) {
          const values = Object.values(invoice);
          const proudctHasValues = values.some((value) => {
            let productValue = value;
            let userSearch = String(this.userSearchInput).trim();
            const dateRegex =
              /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(0[0-9]|[1-9][0-9])$/;
            if (dateRegex.test(userSearch)) {
              const parsedUserSearchDate = moment(userSearch, "DD/MM/YY");
              if (parsedUserSearchDate.isValid()) {
                userSearch = parsedUserSearchDate.toISOString();
              }
              const parsedValueDate = moment(productValue, "DD/MM/YY");
              if (parsedValueDate.isValid()) {
                productValue = parsedValueDate.toISOString();
              }
            }
            return String(productValue).includes(userSearch);
          });
          if (!proudctHasValues) isFiltered = false;
        }
        return isFiltered;
      });
    },
    ...mapGetters({
      getInvoices: "Invoices/getInvoices",
      getIsDesktop: "isDesktop",
    }),
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 20px;
}
.title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  color: $secondaryTextColor;
  gap: 5px;
}
.previous-value-warpper {
  width: 164px;
  text-align: start;
  .previous-value {
    background: $grayBackgroundColor;
    padding: 10px 15px;
    border-radius: 10px;
  }
}
.new-value-warpper {
  text-align: start;
  .new-value {
    border: 1px solid $customerMainColor;
    border-radius: 10px;
    padding: 10px;
    max-width: 164px !important;
    height: 50px !important;
    box-shadow: inset 0px 0px 10px 0px rgba(79, 185, 137, 0.450980392);
  }
}
.title-wrapper {
  .title-section {
    padding: 0px !important;
    align-items: flex-start !important;
    h2 {
      margin: 0px !important;
    }
  }
}

.table-actions-container {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .table-actions-container {
    flex-direction: column;
    align-items: start;
  }

  .invoice-table {
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 490px);
    overflow: scroll;
  }

  .v-table__wrapper {
    max-height: calc(100vh - 490px);
  }
}

// @media (max-width: 1024px) {
//   .modal-container {
//     padding: 20px !important;
//     width: 98%;
//   }
// }

// @media (max-width: 768px) {
//   .modal-container {
//     padding: 20px !important;
//     width: 98%;
//   }
// }

// @media (max-width: 601px) {
//   .modal-container {
//     padding: 20px !important;
//     width: 94%;
//   }
// }
</style>
