import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "./components/pages/LoginPage";
// import Home from './components/pages/Home'
//import NavigationPage from './components/pages/NavigationPage.vue'
// import Cart from './components/pages/Cart'
import Products from "./components/pages/Products";
// import Search from './components/pages/Search'
import SingleOrder from "./components/pages/SingleOrder";
import Orders from "./components/pages/Orders";
import LoginForm from "./components/menus/LoginForm";
// import ArchiveSideBar from './components/menus/ArchiveSIdeBar'
import OrdersSideBar from "./components/menus/OrdersSideBar";
import AgentRequestSideBar from "./components/menus/AgentRequestSideBar";
import CustomersSideBar from "./components/menus/CustomersSideBar";
import store from "./store/index";
// import Categories from './components/menus/Categories.vue'
import Cart from "./components/menus/Cart.vue";
import Customers from "./components/pages/Customers.vue";
import SingelCustomer from "./components/pages/SingelCustomer.vue";
import AgentRequests from "./components/pages/AgentRequests.vue";
import AuthService from "./services/AuthService";
import ResponsiveHome from "./components/views/ResponsiveHome.vue";

function getQueryPayLoad(query, path) {
  if (path.includes("/")) {
    path = path.length > 2 ? path.split("/")[2] : path;
  }
  return {
    ...query,
    [path]: path,
  };
}

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to) {
    // always scroll to top
    if (["home", "orders", "cart", "Order", "loginPage"].includes(to.name))
      return { top: 0 };
  },
  routes: [
    {
      name: "home",
      path: "/",
      components: {
        default: ResponsiveHome,
        sideBar: Cart,
      },
      meta: {
        loginRequired: true,
      },
      beforeEnter: async (to, from, next) => {
        try {
          const agentRequests = store.getters["AgentRequests/getAgentRequests"];
          if (agentRequests?.length == 0) {
            await AuthService.getBigData("agentRequests");
          }
          await AuthService.getBigData("control-panel-agents-list");
          await AuthService.getBigData("control-panel-distlines-list");
          next();
        } catch (error) {
          console.error("Error fetching agentRequests:", error);
          // Handle the error appropriately (e.g., redirect to an error page)
          next(error);
        }
      },
    },
    {
      name: "orders",
      path: "/orders/:category?",
      components: {
        default: Orders,
        sideBar: OrdersSideBar,
      },
      meta: {
        loginRequired: true,
      },
      beforeEnter: async (to, from, next) => {
        try {
          await AuthService.getBigData(
            "orders",
            getQueryPayLoad(to.query, to.path)
          );
          next();
        } catch (error) {
          console.error("Error fetching orders:", error);

          next(error);
        }
      },
    },
    {
      name: "customers",
      path: "/customers/:custType?",
      components: {
        default: Customers,
        sideBar: CustomersSideBar,
      },
      meta: {
        loginRequired: true,
      },
      beforeEnter: async (to, from, next) => {
        try {
          await AuthService.getBigData(
            "customers",
            getQueryPayLoad(to.query, to.path)
          );
          next();
        } catch (error) {
          console.error("Error fetching customers:", error);

          next(error);
        }
      },
    },
    {
      name: "agentRequests",
      path: "/agentRequests",
      components: {
        default: AgentRequests,
        sideBar: AgentRequestSideBar,
      },
      meta: {
        loginRequired: true,
      },
      beforeEnter: async (to, from, next) => {
        try {
          const agentRequests = store.getters["AgentRequests/getAgentRequests"];
          if (agentRequests?.length == 0) {
            await AuthService.getBigData("agentRequests");
          }
          next();
        } catch (error) {
          console.error("Error fetching agentRequests:", error);
          // Handle the error appropriately (e.g., redirect to an error page)
          next(error);
        }
      },
    },
    {
      name: "Category",
      path: "/Category/:category",
      components: {
        default: Products,
        sideBar: Cart,
        mobileSideBar: Cart,
      },
      meta: {
        loginRequired: true,
      },
    },
    {
      name: "Order",
      path: "/Order/:ordname",
      components: {
        default: SingleOrder,
        sideBar: OrdersSideBar,
      },
      meta: {
        loginRequired: true,
      },
      beforeEnter: async (to, from, next) => {
        try {
          await store.dispatch("Orders/getOrder", {
            ordname: to.params.ordname,
          });
          next();
        } catch (error) {
          console.error("Error fetching agentRequests:", error);
          // Handle the error appropriately (e.g., redirect to an error page)
          next(error);
        }
      },
    },
    {
      name: "Customer",
      path: "/Customer/:custname",
      components: {
        default: SingelCustomer,
        sideBar: CustomersSideBar,
      },
      meta: {
        loginRequired: true,
      },
    },
    {
      name: "loginPage",
      path: "/login",
      components: {
        default: LoginPage,
        sideBar: LoginForm,
      },
      meta: {
        redirectToLogin: true,
      },
    },
  ],
});

async function tryToLogin() {
  // console.log('try to login from router.js start')
  document.getElementById("dolimo_loader").style.display = "flex";
  await store.dispatch("AuthModule/tryLogin", "relogin");
  // console.log('try to login from router.js done!')
}

router.beforeEach(async function (to, from, next) {
  // console.log('is authenticated')

  const isAuthenticated = store.getters["AuthModule/isAuthenticated"];

  if (to.meta.loginRequired && !isAuthenticated) {
    await tryToLogin();
    if (store.getters["AuthModule/isAuthenticated"]) {
      next();
    } else {
      next({ name: "loginPage" });
    }
  } else if (to.meta.redirectToLogin && isAuthenticated) {
    next(from);
  } else {
    next();
  }
});

export default router;
