<template>
  <div>
    <Backdrop
      v-if="openModal"
      @close-model="isDatePickerOpen = false"
      :bgColor="'#00000061'"
    >
    </Backdrop>
    <modal-container
      :paddingTop="'10px'"
      :paddingLeft="'10px'"
      :paddingRight="'10px'"
      :paddingBottom="'10px'"
      :maxWidth="'350px'"
      v-if="openModal"
    >
      <v-locale-provider rtl>
        <span class="mb-2 text-end close-icon" @click="closeDatePickerModel">
          <v-icon size="25" color="black" icon="mdi-close-circle-outline" />
        </span>
        <v-date-picker
          prev-icon="mdi-chevron-right"
          next-icon="mdi-chevron-left"
          title="בחירת תאריך"
          v-model="orderDate"
          :header="header"
        >
        </v-date-picker>
      </v-locale-provider>
    </modal-container>
  </div>
</template>
<script>
import Backdrop from "../backdrop.vue";
import ModalContainer from "./modal-container.vue";
export default {
  emits: ["update:modelValue", "trigger-date-picker-update"],
  props: {
    modelValue: {
      type: Date,
    },
    header: {
      type: String,
      default: "",
    },
    openModal: {
      type: Boolean,
      defult: false,
    },
  },
  components: { Backdrop, ModalContainer },
  data() {
    return {
      orderDate: undefined,
      isDatePickerOpen: false,
    };
  },
  mounted() {
    this.orderDate = this.modelValue;
  },
  watch: {
    orderDate(newValue) {
      this.$emit("update:modelValue", newValue);
    },
    isDatePickerOpen(value) {
      if (!value) this.$emit("trigger-date-picker-update", this.orderDate);
      this.orderDate = this.modelValue;
    },
  },
  methods: {
    formattedDate(date) {
      return date.replace("T00:00:00+02:00", "");
    },
    closeDatePickerModel() {
      this.$emit("update:modelValue");
      this.$emit("trigger-date-picker-update", this.orderDate);
      this.orderDate = this.modelValue;
      this.isDatePickerOpen = false;
    },
  },
};
</script>

<style>
.v-input__control {
  padding-left: 10px;
}
.v-field--variant-plain {
  --v-field-padding-top: 12px !important;
  --v-field-padding-bottom: 12px !important;
}
.v-input--density-default {
  --v-input-padding-top: 0px !important;
}
.v-locale--is-rtl .v-date-picker-controls__month {
  flex-direction: row !important;
}

.date-picker-btn {
  border-radius: 50px;
  box-shadow: 0px 0px 4px 0px #00000040;
  width: 100%;
}

.close-icon {
  position: absolute;
  left: 8px;
  top: 8px;
  cursor: pointer;
}

/* .modal-container {
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
} */
</style>
