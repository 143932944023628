<template>
  <div
    class="side-bar-container"
    :class="[
      $store.state.openMenu ? 'down' : 'up',
      $route.name === 'loginPage' ? 'side-nav-login' : 'side',
    ]"
  >
    <slot> </slot>
  </div>
</template>


<script>
export default {
  // props: ["selectedOptionText"],
};
</script>

<style scoped lang="scss">
// .option-selected-conteiner {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 10px 10px;
//   background-color: $primaryColorlight;
//   span {
//     font-size: $mediumTextSize;
//   }
// }
@media only screen and (max-width: 601px) {
  //mobile
  .side-nav-login {
    position: unset !important;
    transform: unset !important;
  }
}
.side{
  padding: 50px 0px 20px 0px;
}
.side-nav-login {
  border-radius: 20px !important;
  padding: 50px;
  position: absolute;
  max-width: 440px;
  left: 0px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100 !important;
  background: white;
  box-shadow: 0px 4px 40px 0px #0000001a;
}
.side-bar-container {
  /* height: 350px;
  width: 290px; */
  z-index: 2;
}

@media (max-width: 992px) {
  .side-bar-container {
    box-shadow: unset;
    padding: 25px;
    margin-bottom: 30px;
  }
}
// @media only screen and (max-width: 350px) {
//   .side-bar-container{
//     padding: 5px;
//   }
// }
</style>