<template>
  <BaseLightBox :boxNumber="3" :mode="mode">
    <template #title>
      <span> {{ $t("SingelDoc.Remarks") }}</span>
    </template>
    <div class="ordernotes">
      <InnerBox :class="[editableClass]">
        <template #content>
          <p>{{ notes ?? "הזמנה ללא הערות" }}</p>
        </template>
      </InnerBox>
    </div>
  </BaseLightBox>
</template>

<script>
import BaseLightBox from "../UI/BaseLightBox.vue";
import InnerBox from "../UI/InnerBox.vue";

export default {
  components: { BaseLightBox, InnerBox },
  props: {
    modelValue: String,
    editable: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
    },
    notes: {
      type: String,
    },
  },
  computed: {
    editableClass() {
      return this.editable && this.mode !== "orders" ? "editable" : "";
    },
    editableTextAreaClass() {
      return this.editable && this.mode !== "cart" && this.mode !== "invoices"
        ? "editable"
        : "";
    },
  },
};
</script>

<style scoped lang="scss">
textarea {
  height: 4.2rem;
  // border-radius: 7px;
  padding: 0 !important;
  // border-color: #dbdbdb;
  width: 100%;
  font-size: $mediumTextSize;
  font-weight: 400;
  line-height: 21.33px;
  text-align: right;
  color: $orderLineColor;
}
textarea.editable {
  border: 1px solid #ececec !important;
  background: #ffffff !important;
  border-radius: 10px;
  padding: 15px !important;
}
textarea:focus {
  outline: unset;
}
.editable {
  border: 1px solid #ececec !important;
  background-color: transparent !important;
}
</style>
