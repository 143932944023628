<template>
  <div
    :style="{ width: width }"
    @click="openDatePickerModel"
    class="date-picker-btn"
    :class="[activeClass]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  emits: ["openModal"],
  props: {
    width: {
      type: String,
    },
    mode: {
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    activeClass() {
      if (this.active) return "active " + this.mode;
      return "";
    },
  },
  methods: {
    openDatePickerModel() {
      this.$emit("openModal");
    },
  },
};
</script>

<style lang="scss">
.v-input__control {
  padding-left: 10px;
}
.v-field--variant-plain {
  --v-field-padding-top: 12px !important;
  --v-field-padding-bottom: 12px !important;
}
.v-input--density-default {
  --v-input-padding-top: 0px !important;
}
.v-locale--is-rtl .v-date-picker-controls__month {
  flex-direction: row !important;
}

.date-picker-btn {
  border-radius: 50px;
  border: 1px solid $borderColor;
  width: 100%;
  box-shadow: unset !important;
}

.date-picker-btn.controlPanel.active {
  border-color: $controlPanelMainColor !important;

  .v-icon {
    color: $controlPanelMainColor !important;
    opacity: 1 !important;
  }

  .v-field__field {
    color: $controlPanelMainColor !important;
  }
}

.date-picker-btn.order.active {
  border-color: $ordersMainColor !important;

  .v-icon {
    color: $ordersMainColor !important;
    opacity: 1 !important;
  }

  .v-field__field {
    color: $ordersMainColor !important;
  }
}

.close-icon {
  position: absolute;
  left: 8px;
  top: 8px;
  cursor: pointer;
}
</style>
