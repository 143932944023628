<template>
  <tr>
    <td>{{ invoice?.curdate }}</td>
    <td>{{ invoice?.duedate }}</td>
    <td>{{ calculateBacklogDays(invoice) }}</td>
    <td>{{ invoice?.ord }}</td>
    <td>{{ invoice?.docno }}</td>
    <td class="remarks-col">{{ truncateText(invoice?.remarks) }}</td>
    <td>{{ invoice?.totalprice?.toLocaleString() }}</td>
    <td>₪</td>
    <td>
      <v-btn
        flat
        :loading="isLoading"
        @click="downloadInvoice(invoice.docId)"
        icon="mdi-file-download"
      ></v-btn>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import { truncateText } from "@/utilities.js";

export default {
  props: {
    invoice: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async downloadInvoice(docId) {
      this.isLoading = true;
      await this.$store.dispatch("Invoices/delevires", docId);
      this.isLoading = false;
    },
    calculateBacklogDays(invoice) {
      return invoice.duedate
        ? moment().diff(moment(invoice.duedate, "DD/MM/YYYY"), "days")
        : "";
    },
    truncateText(text) {
      return truncateText(text, 100);
    },
    formatDate(senddate) {
      return moment(senddate).format("DD/MM/YY");
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  height: 70px;
}
td {
  color: $orderLineColor;
  font-size: $mediumTextSize;
  text-align: center !important;
}
</style>
