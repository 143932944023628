<template>
  <div
    v-if="isSideBarTitleShown"
    class="title-section d-flex align-center ga-2"
    :class="[
      !isOpenMobileSideBar &&
      isMobile &&
      iconName !== 'mdi mdi-briefcase-outline'
        ? 'side-bar-border'
        : '',
    ]"
    @click="openMobileSideBar"
  >
    <!-- <v-icon size="20" :class="iconClass" :icon="iconName" />
    <h2 :class="mode">{{ title }}</h2> -->
    <IconTitle :iconName="iconName" :mode="mode" :title="title" />
  </div>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.cjs.js";
import IconTitle from "../UI/IconTitle.vue";

export default {
  components: { IconTitle },
  props: {
    isSideBarTitleShown: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "סינונים",
    },
    mode: {
      type: String,
    },
    iconName: {
      type: String,
      default: "mdi-filter-multiple-outline",
    },
  },
  methods: {
    openMobileSideBar() {
      if (this.isMobile && !this.isOpenMobileSideBar) {
        this.$store.commit("setIsOpenMobileSideBar", true);
      }
    },
  },
  computed: {
    iconClass() {
      return `${this.mode}-icon`;
    },
    ...mapGetters({
      isMobile: "isMobile",
      isOpenMobileSideBar: "isOpenMobileSideBar",
    }),
  },
};
</script>

<style scoped lang="scss">
.side-bar-border {
  border: 1px solid $grayBorderColor;
  border-radius: 30px;
  width: fit-content;
  padding: 5px 10px !important;
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 50px;
  background: white;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  z-index: 3;
}
</style>
