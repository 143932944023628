<template>
  <div>
    <div class="orders-page">
      <OrdersTable
        @getOrdersPagination="getOrdersPagination"
        :ordersList="getOrders"
        :count="getCountOrders"
      />
    </div>
  </div>
</template>

<script>
import OrdersTable from "../helpers/OrdersTable.vue";
import { mapGetters } from "vuex";
export default {
  components: { OrdersTable },
  mounted() {
    // this.initFilter();
  },
  data() {
    return { };
  },

  methods: {
    async getOrdersPagination(obj) {
      await this.$store.dispatch("Orders/getOrdersPagination", obj);
      // this.initFilter();
    },
    // initFilter() {
    //   this.filteredOrderList = this.getOrders;
    //   const categoriesKeys = Object.keys(this.$route.query);
    //   const paramsKeys = Object.keys(this.$route.params);

    //   for (let categoryKey of categoriesKeys) {
    //     this.filterOrders(categoryKey, this.$route.query[categoryKey]);
    //   }

    //   for (let paramKey of paramsKeys) {
    //     this.filterOrders(paramKey, this.$route.params[paramKey]);
    //   }
    // },
    // filterOrders(categoryKey, categoryValue) {
    //   let filteredOrders = this.filteredOrderList;
    //   let newFilteredOrders = [];
    //   newFilteredOrders = filteredOrders.filter((order) => {
    //     let isFiltered = true;
    //     switch (categoryKey) {
    //       case "distLine":
    //         isFiltered =
    //           order.Customer.DistLine.linekey === parseInt(categoryValue);
    //         break;
    //       case "agentId":
    //         isFiltered = order.Customer.Agent.agent === parseInt(categoryValue);
    //         break;
    //       case "custId":
    //         isFiltered = order.Customer.cust === parseInt(categoryValue);
    //         break;
    //       case "orderDate":
    //         isFiltered = moment(
    //           moment(order.duedate)
    //             .subtract(1, "day")
    //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    //         ).isSame(
    //           moment(categoryValue)
    //             .utc()
    //             .startOf("day")
    //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    //           "day"
    //         );
    //         break;
    //       case "status":
    //         isFiltered = true;
    //         break;
    //       case "category":
    //         if (categoryValue === "allorders") {
    //           isFiltered = true;
    //         } else if (categoryValue === "tommorroworders") {
    //           isFiltered = moment(
    //             moment(order.duedate)
    //               .subtract(1, "day")
    //               .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    //           ).isSame(
    //             moment()
    //               .utc()
    //               .startOf("day")
    //               .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    //             "day"
    //           );
    //         }
    //         break;
    //       case "page":
    //         isFiltered = true;
    //         break;
    //       case "search":
    //         isFiltered = true;
    //         break;
    //     }
    //     return isFiltered;
    //   });
    //   this.filteredOrderList = newFilteredOrders;
    // },
  },
  computed: {
    ...mapGetters({
      getOrders: "Orders/getOrders",
      getCountOrders: "Orders/getCountOrders",
    }),
  },
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 601px) {
  .button-container {
    gap: 10px;
    margin-bottom: 20px !important;
    flex-direction: column !important;
  }

  .orders-page {
    padding: 10px !important;
    margin-top: 10px !important;
    width: 100vw;
  }

  .date-filters {
    gap: 20px !important;
  }
}

.orders-page {
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .orders-page {
    padding: 10px !important;
  }
}

.modal-content {
  font-size: $mediumTextSize;
  color: $modalContentColor;
  font-weight: 400;
  line-height: 21.33px;
  text-align: center;
  margin-top: -20px;

  .email {
    color: #36b079;
  }
}
</style>
