<template>
  <div class="card pa-4">
    <div class="d-flex justify-space-between">
      {{ title }}
      <v-icon size="24" class="icon" :icon="icon"></v-icon>
    </div>
    <div class="text-light text-black font-weight-medium">
      {{ subtitle }}
    </div>
    <div class="text-black font-weight-medium">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  font-size: 20px;
  border-radius: 20px;
  color: white;
}
.icon {
  color: white;
}
.text-light {
  margin-top: 10px;
  font-weight: 300;
  font-size: 16px;
}
</style>