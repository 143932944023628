import InvocieService from "../../services/InvoiceService";
import downloadBase64AsPDF from "../../utility";

export default {
  namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
  state() {
    return {
      invocies: [],
    };
  },
  mutations: {
    setInvoices(state, invoicesList) {
      state.invocies = invoicesList;
    },
  },
  actions: {
    updateInvoices(context, data) {
      context.commit("setInvoices", data);
    },
    async delevires(context, docId) {
      context.dispatch("startLoading", null, { root: true });
      try {
        const base64file = await InvocieService.delevires(docId);
        downloadBase64AsPDF(base64file.data.result, `${docId}.pdf`);
        context.dispatch("stopLoading", null, { root: true });
      } catch (error) {
        if (error?.response?.status === 404 && error?.response?.data?.message) {
          context.dispatch(
            "ShowToast",
            { status: "error", message: error.response.data.message },
            { root: true }
          );
        } else {
          context.dispatch(
            "ShowToast",
            { status: "error", message: "קובץ התעודה להורדה אינו נמצא" },
            { root: true }
          );
        }
      } finally {
        context.dispatch("stopLoading", null, { root: true });
      }
    },
  },
  getters: {
    getInvoices(state) {
      return state.invocies;
    },
    getInvoicesStatuses(state) {
      let statusList = {};
      for (let invoice of state.invocies) {
        if (typeof statusList[invoice.Status.status] === "undefined")
          statusList[invoice.Status.status] = {
            id: invoice.Status.status,
            name: invoice.Status.statusname,
            count: 0,
            enName: invoice.Status.estatusname,
          };
        statusList[invoice.Status.status].count++;
      }
      return statusList;
    },
  },
};
