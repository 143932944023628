<template>
  <BaseLightBox :boxNumber="1" :mode="mode">
    <template #title>
      <span v-if="docNumber !== 'cart'"
        >{{ docTypeName
        }}<span class="doc-number-title">{{ " " + docNumber }}</span></span
      >
      <span v-else-if="docNumber == 'cart'">{{
        $t("SingleOrder.NewOrder")
      }}</span>
    </template>
    <template #top-left-action-btn>
      <slot name="top-left-action-btn"></slot>
    </template>
    <div class="orderinfo">
      <div class="boxes-container d-flex justify-space-between ga-4">
        <div class="box-item">
          <InnerBox :title="$t('SingleOrder.duedate')" :iscart="iscart">
            <template #content>
              <span>{{ orderDate }}</span>
            </template>
          </InnerBox>
        </div>
        <div class="contact-box-container box-item">
          <InnerBox
            :title="$t('SingelDoc.contact')"
            id="contact"
            :iscart="iscart"
          >
            <template #content>
              <span>
                {{
                  `${this.theDoc?.User?.name ?? ""}${
                    this.theDoc?.User?.phoneNumber && !isMobile
                      ? ", " + this.theDoc?.User?.phoneNumber
                      : ""
                  }`
                }}
              </span>
            </template>
          </InnerBox>
        </div>
        <div class="box-item">
          <InnerBox :title="$t('SingelDoc.customerName')" :iscart="iscart">
            <template #content>
              <input
                type="text"
                v-model="docReferenceInput"
                :disabled="!editable"
                @change="
                  () => {
                    if (docReferenceInput.trim().length === 0) {
                      this.$emit('edit-doc-reference', theDoc?.reference);
                      this.docReferenceInput = theDoc?.reference;
                    } else {
                      this.$emit('edit-doc-reference', docReferenceInput);
                    }
                  }
                "
                :class="[isEditableClass]"
              />
            </template>
          </InnerBox>
        </div>
        <div class="box-item" v-if="!iscart">
          <InnerBox :title="$t('SingelDoc.status')">
            <template #content>
              <span>
                {{ this.theDoc?.Status?.statusname }}
              </span>
            </template>
          </InnerBox>
        </div>
      </div>
    </div>
  </BaseLightBox>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.cjs.js";
import BaseLightBox from "../UI/BaseLightBox.vue";
import InnerBox from "../UI/InnerBox.vue";
import moment from "moment";
export default {
  components: {
    BaseLightBox,
    InnerBox,
  },
  data() {
    return {
      docReferenceInput: "",
    };
  },
  props: {
    docTypeName: {
      type: String,
    },
    docNumber: {
      type: Number,
    },
    mode: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    theDoc: {
      type: Object,
      required: true,
    },
    iscart: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.docReferenceInput = this.theDoc?.Customer?.custdes;
  },
  methods: {},
  computed: {
    orderDate() {
      return moment(this.theDoc.duedate).format("DD/MM/YYYY");
    },
    boxItemWidthPercentage() {
      if (this.mode === "cart") return "100%";
      return "35%";
    },
    ...mapGetters({
      isMobile: "isMobile",
    }),
    isEditableClass() {
      return this.editable ? "editable" : "";
    },
    ...mapGetters({
      getCustData: "AuthModule/getCustomerData",
    }),
  },
};
</script>

<style scoped lang="scss">
#contact {
  background-color: white;
  padding: 20px;
}
@media (max-width: 1440px) {
  .orderinfo input {
    margin: 0;
  }
  .boxes-container {
    flex-wrap: wrap-reverse;
  }

  .box-item {
    flex: 1 1 v-bind(boxItemWidthPercentage);
  }
}

.doc-number-title {
  font-weight: 400;
  color: $orderLineColor;
}

select {
  display: block;
  border-radius: 10px;
  font-size: $mediumTextSize;
  font-weight: 400;
  line-height: 21.33px;
  text-align: right;
  color: $orderLineColor;
  opacity: 1;
}

.orderinfo input,
.orderinfo span {
  width: 100% !important;
  border-radius: 10px;
  font-size: $mediumTextSize !important;
  font-weight: 400;
  line-height: 21.33px;
  text-align: right;
  height: fit-content !important;
  color: $orderLineColor;
}

.orderinfo input.editable {
  border: 1px solid $borderColor;
  padding: 15px 10px 15px 10px;
  gap: 5px;
  border-radius: 10px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  background: white;
}
</style>
