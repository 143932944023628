<template>
  <div
    class="d-flex justify-space-between flex-column"
    :style="{ height: '100%' }"
  >
    <div class="cart">
      <v-icon
        icon="mdi-close mb-2"
        v-if="isMobileSideBarOpen"
        @click="closeMobileSideBar"
      />
      <div class="cart-header">
        <div class="d-flex justify-center align-center ga-4">
          <v-icon
            class="cart-icon"
            :color="'white'"
            :icon="'mdi-bell'"
          ></v-icon>
          <div class="title">{{ $t("CartWidget.title") }}</div>
        </div>
        <div @click="allRequests" class="clear-btn">
          <v-icon
            :size="25"
            color="#801512"
            :icon="'mdi-notification-clear-all'"
          ></v-icon>
          <span
            @click="
              () => {
                this.$router.push({ name: 'agentRequests' });
              }
            "
            class="light-font"
            >{{ $t("CartWidget.allRequests") }}</span
          >
        </div>
      </div>
      <div
        class="addAgentRequest-warpper"
        @click="isAddAgentRequestModal = true"
        v-if="!getUserRole?.agentapp"
      >
        <span>הוספת בקשה</span>
        <v-icon class="addAgentRequest" :size="25" icon="mdi-plus"> </v-icon>
      </div>
      <div class="d-flex flex-column ga-5 items-warpper">
        <TransitionGroup tag="ul" name="fade" class="transition">
          <AgentRequest
            v-for="agentRequest in agentRequests"
            :key="agentRequest.id"
            :isAdmin="getUserRole?.agentapp"
            :agentRequest="agentRequest"
          />
        </TransitionGroup>
      </div>
    </div>
    <AddAgentRequestModal
      v-if="isAddAgentRequestModal"
      :openModal="isAddAgentRequestModal"
      :getUniqueCustomer="getUniqueCustomer"
      @closeModal="isAddAgentRequestModal = false"
      @addAgentRequest="addAgentRequest"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AgentRequest from "../helpers/AgentRequest.vue";
import AddAgentRequestModal from "../UI/modals/AddAgentRequestModal.vue";
// import NotFound from "../helpers/NotFound.vue";
export default {
  data() {
    return {
      isAddAgentRequestModal: false,
      sortedAgentRquest: [],
    };
  },
  components: {
    // NotFound,
    AgentRequest,
    AddAgentRequestModal,
  },
  mounted() {
    this.sortedAgentRquest = [...this.agentRequests];
  },
  methods: {
    GotoCartPage() {
      if (this.Cart?.OrderLines?.length > 0) {
        this.closeMobileSideBar();
        this.$router.push("/cart");
      }
    },
    closeMobileSideBar() {
      this.$store.commit("setIsOpenMobileSideBar", false);
    },
    removeProduct(product) {
      this.$store.dispatch("Orders/removeProductFromCart", product);
    },
    allRequests() {
      // this.$store.dispatch("allRequests");
    },
    updateProduct(obj) {
      var { product, quantity } = obj;
      this.$store.dispatch("Parts/updateProduct", { product, quantity });
    },
    async addAgentRequest(obj) {
      let newObj = { ...obj, agent: this.getUser.agent };
      await this.$store.dispatch("AgentRequests/addAgentRequest", newObj);
      this.isAddAgentRequestModal = false;
    },
  },
  computed: {
    ...mapGetters({
      isMobileSideBarOpen: "isOpenMobileSideBar",
      agentRequests: "AgentRequests/getAgentRequests",
      getUserRole: "AuthModule/getUserRole",
      getUser: "AuthModule/getUser",
      getUniqueCustomer: "Customers/getUniqueCustomer",
    }),
  },
};
</script>
<style>
.agnet-request:last-child .v-divider {
  display: none;
}
</style>
<style scoped lang="scss">
@media only screen and (max-width: 601px) {
  // .cart {
  //   height: unset !important;
  // }
}
/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.transition {
  margin-right: 10px;
  position: relative;
  padding: 20px;
  direction: rtl !important;
  list-style-type: none;
}

.items-warpper {
  direction: ltr;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 260px);
}

@media only screen and (max-width: 768px) {
  .items-warpper {
    max-height: 100%;
    padding-bottom: 100px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.cart {
  padding-top: 20px;
  padding-left: 20px;
  min-height: calc(100vh - 170px);
}

@media (max-width: 601px) {
  .cart {
    padding: 5px;
    height: calc(100vh - 210px);
  }
}

@media (min-width: 601px) {
  .empty-cart {
    margin-top: 140px;
  }
}

.product {
  &:first-child {
    padding-top: 0px;
  }
}
.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .cart-icon {
    background: $controlPanelMainColor;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .title {
    font-weight: 600;
    font-size: $mediumTextSize;
  }

  .clear-btn {
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    color: $controlPanelMainColor;
    span {
      text-decoration: underline;
    }
  }
  .clear-btn.disable {
    opacity: 50%;
    pointer-events: none;
  }
}
.addAgentRequest-warpper {
  color: $customerMainColor;
  display: flex;
  align-items: center;
  border: 1px solid $customerMainColor;
  width: fit-content;
  padding: 5px 10px;
  gap: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    background: $customerMainColor;
    color: white;
    i:before {
      color: white !important;
    }
  }
}
.button-warpper {
  &:lang(he) {
    margin-right: -24px;
  }
  &:lang(en) {
    margin-right: -12px;
    margin-left: -12px;
  }
}

@media (max-width: 601px) {
  .button-warpper {
    margin: 0 !important;
  }
}

.button-overly {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 100%;
}
.order-summary {
  border-radius: 0px !important;
  background: $controlPanelMainColor;
}
.btn-text {
  color: white;
  font-size: 22px;
  font-weight: 400;
}
</style>
