const url =
  process.env.NODE_ENV === "production"
    ? `https://orders.balkan1936.co.il/`
    : `https://orders.balkan1936.co.il/`;

module.exports = {
  baseUrl: url,
  newOrderUrl: (passkey, userPhone, cust, agent, orderId) => {
    return `${url}passkey?passkey=${passkey}&phone=${userPhone}&cust=${cust}&agent=${agent}${
      orderId ? `&orderId=${orderId}` : ""
    }`;
  },
};
