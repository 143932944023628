import Api from "./Api";
import OrderService from "@/services/OrderService";
import AgentRequestSevrice from "@/services/AgentRequestSevrice";
import store from "../store/index";
import ControlPanelService from "./ControlPanelService";

export default {
  changeLang(obj) {
    return Api().post("change-language", obj);
  },
  createNewPassword(email, password, token) {
    return Api().post("create-new-password", {
      email: email,
      password: password,
      token: token,
    });
  },
  resetPass(userEmail) {
    return Api().post("reset-password", { userEmail: userEmail });
  },
  login(credentials) {
    return Api().post("verify-phone", credentials);
  },
  verifyOtp(credentials) {
    return Api().post("verify-otp", credentials);
  },
  getData() {
    return Api().post("customer");
  },
  resetIsAdminAccess() {
    let user = localStorage.getItem("user");
    if (user) user = JSON.parse(user);
    if (user?.UserRoll?.agentapp) user.UserRoll.agentapp = false;
    localStorage.setItem("user", JSON.stringify(user));
    store.dispatch("AuthModule/setUser", user);
  },
  async getBigData(dataType, query = null) {
    /*-get user data:-*/
    // get quaninties

    store.dispatch("startLoading", null, { root: true });

    try {
      if (dataType === "orders") {
        // await OrderService.getOrders({});
        console.log(query);
        
        const ordersa = await OrderService.getOrdersPagination(query);
        await store.commit("Orders/orderCount", ordersa.data.count);
        await store.dispatch("Orders/updateOrders", ordersa.data.orders.rows);
        await store.dispatch("ControlPanel/getFilters");
        // store.dispatch("Orders/updateOrders", ordersa.data.orders);
      } else if (dataType === "agentRequests") {
        let agentRequests = await AgentRequestSevrice.getAgentRequests();
        console.log("agentRequests data:");
        console.log(agentRequests.data);
        await store.dispatch(
          "AgentRequests/updateAgentRequests",
          agentRequests.data
        );
        await store.dispatch("Customers/getCustomers");
      } else if (dataType === "customers") {
        await store.dispatch("Customers/getCustomersPagination", query);
        await store.dispatch("ControlPanel/getFilters");
      } else if (dataType === "control-panel-agents-list") {
        try {
          await store.dispatch("ControlPanel/agentsList");
        } catch (error) {
          if (error?.response?.status === 403) {
            window.location.reload();
          } else throw new Error();
        }
      } else if (dataType === "control-panel-distlines-list") {
        try {
          await store.dispatch("ControlPanel/distLinesList");
        } catch (error) {
          if (error?.response?.status === 403) {
            window.location.reload();
          } else throw new Error();
        }
      } else {
        let quaninties = await ControlPanelService.getAgentListCounts();
        quaninties = quaninties.data;
        store.dispatch("Orders/updateOpenOrders", quaninties.openOrdersCount);
        store.dispatch(
          "AgentRequests/updateOpenAgentRequests",
          quaninties?.openAgentRequest ?? 0
        );
        store.dispatch(
          "Customers/updateActiveCustomers",
          quaninties.activeCustomersCount
        );
      }
      return true;
    } catch {
      return false;
    } finally {
      store.dispatch("stopLoading", null, { root: true });
    }
  },
};
