function truncateText(text, maxLength) {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}

function formatPrice(price) {
  const myPrice = price < 0 ? price * -1 : price;
  if (Number.isInteger(myPrice)) {
    return myPrice.toLocaleString();
  } else {
    return parseFloat(myPrice.toFixed(2)).toLocaleString();
  }
}

module.exports = {
  truncateText,
  formatPrice,
};
