<template>
  <div class="d-flex align-center ga-2">
    <v-icon size="20" :class="iconClass" :icon="iconName" />
    <h2 :class="mode">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    mode: {
      type: String,
    },
    iconName: {
      type: String,
      default: "mdi-filter-multiple-outline",
    },
    titleSize: {
      type: String,
      default: "20px",
    },
  },
  computed: {
    iconClass() {
      return `${this.mode}-icon`;
    },
  },
};
</script>

<style scoped lang="scss">
.title-section {
  padding: 0px 20px 0px 20px;
}

.v-icon {
  border-radius: 50%;
  padding: 15px;
}

.v-icon.orders-icon {
  color: white;
  background-color: $buttonColor;
}

.customers-icon {
  color: white;
  background-color: $buttonColor;
}

.controlPanel-icon {
  color: white;
  background-color: $controlPanelMainColor;
}

h2 {
  font-size: v-bind(titleSize);
  font-weight: 700;
  line-height: 35.55px;
  text-align: right;
  margin: 0px !important;
}

h2.orders {
  color: $ordersMainColor;
}

h2.customers {
  color: $customerMainColor;
}

h2.controlPanel {
  color: $controlPanelMainColor;
}
</style>
