<template>
  <div class="product-modal">
    <v-icon
      color="#757575"
      @click="$emit('closeModel')"
      size="25px"
      class="product-modal__close-icon"
      icon="mdi-close-circle-outline"
    />
    <div class="product-modal__image-container">
      <span
        class="product-modal__stock-label"
        :class="{
          'product-modal__stock-label--out-of-stock': !product.AvailableInStock,
        }"
      >
        {{
          product.AvailableInStock
            ? $t("Products.productItem.AvailableInStock")
            : $t("Products.productItem.outOfStock")
        }}
      </span>
      <img
        :src="product.image"
        :alt="product.sku"
        class="product-modal__image"
      />
    </div>
    <div class="product-modal__details">
      <div class="product-modal__grid">
        <ProductDetailItem
          :label="$t('Products.productItem.sku')"
          :value="product?.sku"
        />
        <ProductDetailItem
          :label="$t('Products.productItem.width')"
          :value="tofix(product.width, 2)"
        />
        <ProductDetailItem
          :label="$t('Products.productItem.unitOfSale')"
          :value="product.unitOfSale"
        />
        <ProductDetailItem
          :label="$t('Products.productItem.height')"
          :value="tofix(product.height, 2)"
        />
        <ProductDetailItem
          :label="$t('Products.productItem.category')"
          :value="product.category"
        />
        <ProductDetailItem
          :label="$t('Products.productItem.length')"
          :value="tofix(product.length, 2)"
        />
        <ProductDetailItem
          :label="$t('Products.productItem.status')"
          :value="product.status"
          isLabel
        />
        <ProductDetailItem
          :label="$t('Products.productItem.weight')"
          :value="tofix(product.weight, 2)"
        />
        <ProductDetailItem
          :label="$t('Products.productItem.volume')"
          :value="tofix(product.volume, 3)"
        />
      </div>
      <div class="product-modal__divider" />
      <ProductDetailItem
        :label="$t('Products.productItem.description')"
        :value="product.description"
        class="product-modal__description"
      />
      <div class="product-modal__divider" />
      <Prices
        :discountPercent="product?.discountPercent"
        :discountPrice="product?.discountPrice"
        :originPrice="product?.originPrice"
      />
    </div>
  </div>
</template>

<script>
import Prices from "../../helpers/Prices.vue";
import ProductDetailItem from "../../helpers/ProductDetailItem.vue";

export default {
  components: {
    Prices,
    ProductDetailItem,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    tofix(number, fix) {
      return Number(number).toFixed(fix);
    },
  },
};
</script>

<style scoped lang="scss">
.product-modal {
  display: flex;
  flex-direction: row;
  gap: 10px;

  &__image-container {
    flex-basis: 45%;
    position: relative;
  }

  &__close-icon {
    z-index: 100;
        top: 20px;
    position: absolute;
  }

  &__stock-label {
    display: inline-block;
    border-radius: 50px;
    padding: 0 10px 0 20px;
    background: #e6f6ef;
    color: #36b079;
    position: relative;
    margin-bottom: 20px;

    &::before {
      content: "";
      height: 6px;
      width: 6px;
      position: absolute;
      top: 50%;
      background-color: #36b079;
      left: 9px;
      transform: translateY(-50%);
      border-radius: 50%;
    }

    &--out-of-stock {
      color: #d13257;
      background: #f2e3e7;

      &::before {
        background-color: #d13257;
      }
    }
  }

  &__image {
    width: 240px;
    height: 220px;
    object-fit: cover;
  }

  &__details {
    flex-basis: 55%;
    width: 300px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 10px;
  }

  &__divider {
    width: 100%;
    border-top: 1px solid rgba(212, 212, 212, 0.39);
    margin: 15px 0;
  }

  &__description {
    line-height: 21px;
  }

  @media only screen and (max-width: 601px) {
    flex-direction: column;

    &__image-container {
      flex-basis: auto;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }

    &__image {
      width: 220px;
      height: auto;
    }

    &__details {
      flex-basis: auto;
      width: 100%;
    }

    &__grid {
      grid-template-columns: 2fr 1fr;
    }

    &__description,
    .prices {
      grid-column: 1 / -1;
    }
  }
}
</style>