<template>
  <div class="products">
    <BorderWrapper>
      <baseSearch
        v-if="
          ($route.name !== 'home' && $route.name !== 'loginPage') ||
          $store.getters.isDekstop
        "
        :isSearchHeader="$vuetify.display.mobile ? true : false"
        class="d-flex align-center justify-center search"
        :minWidth="$store.getters.isMobile ? '' : '744px'"
      />
      <NotFound
        v-if="productsList.length === 0"
        imageSrc="/images/not_found.svg"
        title="הפריט לא נמצא..."
      />
      <div class="grid">
        <productItem
          v-for="(product, index) in productsList"
          :product="product"
          :key="index"
          @updateProduct="updateProduct"
          @moreInfo="openMoreInfoModel"
        />
      </div>
      <v-slide-y-transition>
        <modal-container
          :gap="'0px'"
          :borderRadius="'20px'"
          :padding="'40px'"
          :paddingTop="'65px'"
          v-if="moreInfoModel"
        >
          <Backdrop @close-model="moreInfoModel = false" :bgColor="'#00000061'">
          </Backdrop>

          <productModel
            @closeModel="moreInfoModel = false"
            :product="moreInfoProduct"
          />
        </modal-container>
      </v-slide-y-transition>
    </BorderWrapper>
  </div>
</template>

<script>
import BaseSearch from "../menus/BaseSearch.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import productItem from "../helpers/productItem.vue";
import productModel from "../UI/modals/productModel.vue";
import modalContainer from "../UI/modals/modal-container.vue";
import Backdrop from "../../components/UI/backdrop.vue";
import NotFound from "../helpers/NotFound.vue";
import BorderWrapper from "../UI/BorderWrapper.vue";
export default {
  components: {
    BaseSearch,
    productItem,
    modalContainer,
    Backdrop,
    productModel,
    NotFound,
    BorderWrapper,
  },
  mounted() {
    this.filterParts();
  },
  watch: {
    "$route.query.search"() {
      this.filterParts();
    },
    Parts: {
      immediate: true,
      deep: true,
      handler() {
        this.filterParts();
      },
    },
  },
  data() {
    return {
      productsList: [],
      moreInfoModel: false,
      moreInfoProduct: {},
    };
  },
  methods: {
    openMoreInfoModel(product) {
      this.moreInfoModel = true;
      this.moreInfoProduct = product;
    },
    updateProduct(obj) {
      var { product, quantity } = obj;
      //צריך להוסיף פה את הבדיקה אם צריך להביא מוצר חלופי או לא
      this.$store.dispatch("Parts/updateProduct", { product, quantity });
    },
    filterParts() {
      if (!this.Parts) return "אין מוצרים";
      this.productsList = this.Parts.filter((part) => {
        try {
          for (let key in this.$route.query) {
            if (key === "search") {
              let allSearchFields = [];
              allSearchFields = [part.sku, part.category];
              let infields = false;

              for (let i in allSearchFields) {
                if (!allSearchFields[i]) continue;
                if (
                  allSearchFields[i]
                    .toLowerCase()
                    .includes(this.$route.query.search.toLowerCase())
                ) {
                  infields = true;
                }
              }

              if (!infields) return false;
            }
          }
          return true;
        } catch (err) {
          console.log(err);
        }
      });
    },
    ...mapActions({
      toggleFavorite: "Parts/toggleFavorite",
    }),
  },
  computed: {
    ...mapGetters({
      Parts: "Parts/getParts",
      getCustname: "AuthModule/getCustname",
      getUserLang: "AuthModule/getUserLang",
    }),
  },
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 601px) {
  .products {
    padding: 20px !important;
    padding-top: 0px !important;
    padding-bottom: 30px !important;
    margin-bottom: 100px;
  }
}
.search {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 15px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 32px;
}
</style>
