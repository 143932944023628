<template>
  <div>
    <SelectList
      v-model="selectedCategory"
      :showSearchBar="queryKey === 'custId'"
      :Categories="Categories"
      :mode="mode"
      :queryKey="queryKey"
      :defaultValue="defaultValue"
    />
  </div>
</template>

<script>
import SelectList from "../UI/SelectList.vue";

export default {
  props: {
    Categories: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    queryKey: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      required: true,
    },
  },
  components: {
    SelectList,
  },
  data() {
    return {
      editable: false,
      selectedCategory: null,
    };
  },
  mounted() {
    // get the id from the query:
    const queryKeys = Object.keys(this.$route.query);
    for (let queryKey of queryKeys) {
      if (queryKey.includes(this.queryKey)) {
        this.selectedCategory = this.Categories.find((category) => {
          return category.id === parseInt(this.$route.query[queryKey]);
        });
        if (this.selectedCategory) break;
      }
    }
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.v-list {
  color: white !important;
}
.customers.v-list-item--active {
  background: $customerMainColor;
}
.orders.v-list-item--active {
  background: $ordersMainColor;
}
.agentRequests.v-list-item--active {
  background: $controlPanelMainColor;
}

.menu-collection {
  .v-list-item-title {
    font-size: $mediumTextSize;
    font-weight: 400;
    line-height: 21.33px;
    text-align: right;
    color: $statusListColor;
  }
}
</style>
