<template>
  <div class="d-flex flex-row align-end" :class="{ 'ga-3': isLongPrice }">
    <div class="d-flex flex-column">
      <div class="origin-price">₪{{ originPrice }}</div>
      <div class="discount-percent">{{ discountPercent ?? 0 }}%-</div>
    </div>
    <div class="discount-price">₪{{ discountPrice }}</div>
  </div>
</template>

<script>
export default {
  props: {
    discountPrice: Number,
    originPrice: Number,
    discountPercent: Number,
  },
  computed: {
    isLongPrice() {
      if (("" + this.discountPrice)?.length > 3) return false;
      else return true;
    },
  },
};
</script>

<style scoped lang="scss">
.origin-price {
  font-weight: 400px;
  color: #b7b7b7;
  text-decoration: line-through;
  font-size: $smallTextSize;
}
.discount-percent {
  font-size: 14px;
  color: #36b079;
}
.discount-price {
  line-height: 22px;
  font-size: 22px;
}
</style>