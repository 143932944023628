<template>
  <BaseLightBox :boxNumber="2" :mode="mode">
    <template #title>
      <span> {{ docTypeName }}</span>
    </template>
    <ProductWidget
      v-for="line in orderLines"
      :key="line.ordi"
      :line="line"
      :orderNumber="ordname"
      :orderReference="reference"
      :editAble="editable"
      :quantButtonTitle="quantButtonTitle"
      :cust="cust"
      @updateCounter="updateLineInfo"
      @updateRefundReason="updateLineInfo"
      :mode="mode"
    >
    </ProductWidget>
    <slot name="bottom-action-btn"></slot>
    <div class="doc-summary-section">
      <slot name="doc-summary"></slot>
    </div>
  </BaseLightBox>
</template>

<script>
import BaseLightBox from "../UI/BaseLightBox.vue";
import ProductWidget from "./ProductWidget.vue";
export default {
  components: { BaseLightBox, ProductWidget },
  emits: ["updateLineInfo"],
  data() {
    return {
      selectedValue: null,
    };
  },
  props: {
    mode: {
      type: String,
    },
    docTypeName: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    orderLines: {
      type: Array,
    },
    reference: {
      type: String,
      default: null,
    },
    ordname: {
      type: String,
      default: null,
    },
    cust: {
      type: Number
    },
    quantButtonTitle: {
      type: String,
    },
  },
  mounted() {
    console.log('orderLines');
    console.log(this.orderLines);
  },
  methods: {
    updateLineInfo(updatedLine) {
      this.$emit("updateLineInfo", updatedLine);
    },
    isObjectEmpty(obj) {
      if (
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      ) {
        return false;
      } else return true;
    },
  },
};
</script>

<style scoped lang="scss">
.doc-summary-section {
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 30px;
}
</style>
