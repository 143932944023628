<template>
  <div
    class="d-flex ga-5 justify-space-between align-cetner agnet-request"
    :style="{ 'flex-direction': horizontal ? 'row' : 'column', width: '100%' }"
  >
    {{}}
    <div
      class="d-flex ga-4"
      :style="{ 'flex-direction': horizontal ? 'row' : 'column' }"
    >
      <div class="d-flex flex-row ga-3">
        <v-avatar color="#f5f5f5" icon="mdi-account" size="50"></v-avatar>
        <div>
          <span class="black font-16 font-weight-bold">
            {{ agentReq?.agent }}
          </span>
          <div class="gray font-16">
            {{ agentReq?.date }}
          </div>
        </div>
      </div>
      <div v-if="horizontal">
        <v-divider class="ma-0" vertical></v-divider>
      </div>
      <div>
        <span class="text font-18" v-html="agentReq.text" />
      </div>
    </div>
    <div
      v-if="
        !agentRequest.isAccepct &&
        !agentRequest.isRejection &&
        !agentRequest.accepctByOther &&
        !agentRequest.rejectedByUser &&
        isAdmin
      "
      class="button-wrappar"
    >
      <v-btn
        @click="acceptRequest"
        class="acssept-btn normal-font"
        width="130"
        height="50px"
      >
        אישור
      </v-btn>
      <v-btn
        @click="rejectRequest"
        class="cancel-btn normal-font"
        width="130px"
        height="50px"
      >
        דחייה
      </v-btn>
    </div>

    <div
      class="d-flex align-center"
      v-else-if="
        agentRequest.isAccepct &&
        agentRequest.accepctByOther === getCurrentUser.name &&
        getUserRole?.agentapp
      "
    >
      <span class="accepctByUser">אישרת בקשה זו</span>
      <span
        @click="clearRequest"
        v-if="agentRequest.isAccepct"
        class="mr-5 cancel cursor-pointer"
        >⮌ביטול</span
      >
    </div>
    <div class="d-flex align-center" v-else-if="agentRequest.isAccepct">
      <span class="accepctByUser"
        >בקשתך אושרה ע"י
        <span class="font-weight-bold">{{
          agentRequest.accepctByOther
        }}</span></span
      >
    </div>
    <div
      class="d-flex align-center"
      v-else-if="
        agentRequest.isRejection &&
        agentRequest.rejectedByUser === getCurrentUser.name &&
        getUserRole?.agentapp
      "
    >
      <span class="rejectByUser">דחיתה בקשה זו</span>
      <span
        @click="clearRequest"
        v-if="agentRequest.isRejection"
        class="mr-5 cancel cursor-pointer"
        >⮌ביטול</span
      >
    </div>
    <div class="d-flex align-center" v-else-if="agentRequest.rejectedByUser">
      <span class="rejectByUser"
        >בקשתך נדחתך ע"י
        <span class="font-weight-bold">{{
          agentRequest.rejectedByUser
        }}</span></span
      >
    </div>
    <div class="d-flex align-center" v-else>
      <span class="waitingRequest">בקשתך ממתינה לאישור</span>
    </div>
    <div v-if="!isBootomDivederHidden">
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex/dist/vuex.cjs.js";

export default {
  emits: ["accept", "reject", "clear"],
  props: {
    agentRequest: {
      type: Object,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    isBootomDivederHidden: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "AuthModule/getUser",
      getUserRole: "AuthModule/getUserRole",
    }),
    agentReq() {
      return {
        agent: this.agentRequest.Agent?.agentname,
        date: moment(this.agentRequest.date).format("DD/MM/YYYY"),
        text: `מבקש עבור לקוח <b> ${this.agentRequest?.Customer?.custdes}</b> <br/>
        ${this.agentRequest.text}`,
        isAccepct: this.agentRequest.isAccepct,
        isRejection: this.agentRequest.isRejection,
        accepctByOther: this.agentRequest.accepctByOther,
        rejectedByUser: this.agentRequest.rejectedByUser,
      };
    },
  },
  methods: {
    addCommas(number) {
      return number;
      // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clearRequest() {
      this.$store.dispatch(
        "AgentRequests/clearAgentRequest",
        this.agentRequest.id
      );
    },
    acceptRequest() {
      this.$store.dispatch("AgentRequests/acceptAgentRequest", {
        requestId: this.agentRequest.id,
        userObj: this.getCurrentUser,
      });
    },
    rejectRequest() {
      this.$store.dispatch("AgentRequests/rejectAgentRequest", {
        requestId: this.agentRequest.id,
        userObj: this.getCurrentUser,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-divider {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.accepctByUser {
  border-radius: 50px;
  border: 1px solid #36b07917;
  padding: 5px 20px;
  background: $lightGreenColor;
  color: #36B079;
}

.rejectByUser {
  border-radius: 50px;
  border: 1px solid #36b07917;
  padding: 5px 20px;
  background: $lightRedColor;
  color: #d13257;
}

.waitingRequest {
  border-radius: 50px;
  border: 1px solid #b0933617;
  padding: 5px 20px;
  background: $lightWarningColor;
  color: #fb8c00;
}

.cancel {
  text-decoration: underline;
  color: $hrefLinkColor;
}

.button-wrappar {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 425px) {
  .button-wrappar {
    justify-content: center;
  }
}

.acssept-btn {
  color: #36B079 !important;
}
</style>
