<template>
  <v-table fixed-header fixed-footer height="500px">
    <thead>
      <th>{{ $t(`invocies.table.columns.invoiceDate`) }}</th>
      <th>{{ $t(`invocies.table.columns.dueDate`) }}</th>
      <th>{{ $t(`invocies.table.columns.backLogDays`) }}</th>
      <th>{{ $t(`invocies.table.columns.order`) }}</th>
      <th>{{ $t(`invocies.table.columns.invoice`) }}</th>
      <th>{{ $t(`invocies.table.columns.details`) }}</th>
      <th>{{ $t(`invocies.table.columns.price`) }}</th>
      <th>{{ $t(`invocies.table.columns.currency`) }}</th>
      <th>{{ $t(`invocies.table.columns.download`) }}</th>
    </thead>
    <tbody>
      <invoices-table-tr
        v-for="invoice in invoicesList"
        :key="invoice.docId"
        :invoice="invoice"
      />
    </tbody>
    <tfoot>
      <th class="sum-title">סה"כ</th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th class="sum-value font-weight-normal">
        {{ sum }}
      </th>
      <th>₪</th>
      <th></th>
    </tfoot>
  </v-table>
</template>

<script>
import moment from "moment";
import { truncateText } from "@/utilities.js";
import InvoicesTableTr from "./InvoicesTableTr.vue";

export default {
  components: { InvoicesTableTr },
  props: {
    tableMode: {
      type: String,
      default: null,
    },
    invoicesList: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    sum() {
      if (!this.invoicesList) return 0;
      const sum = this.invoicesList.reduce((acc, cur) => {
        const price = cur.totalprice < 0 ? cur.totalpric * -1 : cur.totalprice;
        return acc + price;
      }, 0);
      return sum.toLocaleString();
    },
  },
  methods: {
    async downloadInvoice(docId) {
      this.isLoading = true;
      await this.$store.dispatch("Invoices/delevires", docId);
      this.isLoading = false;
    },
    calculateBacklogDays(invoice) {
      return invoice.duedate
        ? moment().diff(moment(invoice.duedate, "DD/MM/YYYY"), "days")
        : "";
    },
    truncateText(text) {
      return truncateText(text, 100);
    },
    formatDate(senddate) {
      return moment(senddate).format("DD/MM/YY");
    },
  },
};
</script>

<style lang="scss">
.v-table__wrapper {
  overflow-x: scroll;
  max-height: 500px;
}
</style>
<style scoped lang="scss">
@media only screen and (max-width: 601px) {
  thead th {
    font-size: 15px !important;
    min-width: 100px;
  }
  tbody td {
    font-size: 15px !important;
    min-width: 100px;
  }

  .sum-title,
  .sum-value {
    font-size: 15px !important;
  }
}
thead {
  border-radius: 0px 50px 50px 0px;
  background-color: #f5f5f5;
  position: sticky;
  th {
    font-size: $mediumTextSize;
    font-weight: 400;
    padding: 10px 0px;

    &:first-child {
      border-radius: 0px 50px 50px 0px;
    }
    &:last-child {
      border-radius: 50px 0px 0px 50px;
    }
  }
}
tfoot {
  border-radius: 0px 50px 50px 0px;
  background-color: #f5f5f5;
  color: #868686;
  th {
    font-size: $mediumTextSize;
    font-weight: 700;
    padding: 10px 0px;
    min-width: 150px;

    &:first-child {
      border-radius: 0px 50px 50px 0px;
    }
    &:last-child {
      border-radius: 50px 0px 0px 50px;
    }
  }
  .font-weight-normal {
    font-weight: 400;
  }
}
</style>
