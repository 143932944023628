import Api from "./Api";

export default {
  getInvoices(obj) {
    return Api().post("invoices", obj);
  },
  delevires(docId) {
    return Api().get(`delevires/${docId}`);
  }
};
