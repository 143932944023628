<template>
  <div class="singel-doc-page">
    <div v-if="theInvoice !== null" class="OrderView d-flex flex-column ga-4">
      <DocInfo
        :docNumber="cartOrOrder"
        :docTypeName="$t('SingelInvoice.invoiceNumber')"
        :theDoc="theInvoice"
        :iscart="iscart"
        mode="invoices"
        @edit-doc-shppingCode="changeTheDocShippingCode"
        @edit-doc-reference="changeTheDocReference"
      >
        <template #top-left-action-btn>
          <v-btn flat @click="printInvoice" class="send-invoice">
            <div class="d-flex align-center ga-1">
              <v-icon
                size="24"
                color="white"
                icon="mdi-cloud-arrow-down-outline"
              />
              <span>{{ $t("SingelInvoice.printCopy") }}</span>
            </div>
          </v-btn>
        </template>
      </DocInfo>

      <DocProducts
        :ordname="theInvoice.ordname"
        :docTypeName="$t('SingelInvoice.invoiceDetails')"
        :reference="theInvoice.reference"
        :orderLines="theInvoice.OrderLines"
        :editable="isOnCreateRefundMode"
        :quantButtonTitle="$t('SingelInvoice.addQuant')"
        @updateLineInfo="updateLineInfo"
        mode="invoices"
      >
        <template #bottom-action-btn>
          <div class="invoice-butttons-container mt-10">
            <v-btn
              v-if="!isOnCreateRefundMode"
              flat
              @click="createNewRefund"
              class="send-invoice"
            >
              <div class="d-flex align-center ga-1">
                <v-icon size="24" color="white" icon="mdi-undo-variant" />
                <span>{{ $t("SingelInvoice.refundRequest") }}</span>
              </div>
            </v-btn>
            <v-btn v-else flat @click="sendRefundRequest" class="send-invoice">
              <div class="d-flex align-center ga-1">
                <v-icon size="24" color="white" icon="mdi-note-check" />
                <span>{{ $t("SingelInvoice.sendRefundRequest") }}</span>
              </div>
            </v-btn>
            <v-btn
              class="cancel-invoice"
              v-if="isOnCreateRefundMode"
              flat
              @click="cancelNewRefund"
            >
              <div class="d-flex align-center ga-1">
                <v-icon
                  size="24"
                  color="black"
                  icon="mdi-close-circle-outline"
                />
                <span>{{ $t("SingelInvoice.cancelRefundRequest") }}</span>
              </div>
            </v-btn>
          </div>
        </template>
      </DocProducts>

      <DocNotes mode="invoices" />
    </div>
    <modal-container
      v-if="isDownloadFileModalOpen"
      iconSize="50px"
      :iconColor="'#36B079'"
      icon="mdi-cloud-check"
      :gap="'30px'"
      :borderRadius="'20px'"
      :padding="'40px'"
      :title="$t('SingelInvoice.printModalTitle')"
    >
      <Backdrop
        @close-model="isDownloadFileModalOpen = false"
        :bgColor="'#00000061'"
      >
      </Backdrop>
      <div class="d-flex flex-column ga-5 align-center">
        <div class="modal-content">
          {{ $t("SingelInvoice.printModalContent") }}
          <span class="email">{{ getUserEmail }}</span>
        </div>
        <v-btn
          class="close-modal-btn"
          flat
          @click="isDownloadFileModalOpen = false"
        >
          <div class="d-flex align-center ga-1">
            <v-icon size="24" color="black" icon="mdi-close-circle-outline" />
            <span> {{ $t("SingelInvoice.printModalCloseBtn") }}</span>
          </div>
        </v-btn>
      </div>
    </modal-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import DocInfo from "../helpers/DocInfo.vue";
import DocNotes from "../helpers/DocNotes.vue";
import DocProducts from "../helpers/DocProducts.vue";
import ModalContainer from "../UI/modals/modal-container.vue";
import Backdrop from "../UI/backdrop.vue";

export default {
  components: {
    DocInfo,
    DocNotes,
    DocProducts,
    ModalContainer,
    Backdrop,
  },
  mounted() {
    this.cartOrOrder = this.$route.params.ordname;
    this.iscart = false;
    this.theInvoice = {
      ...this.$store.getters["Orders/getOrder"](this.cartOrOrder),
    };
    this.hasDeliveries = this.theInvoice.Deliveries.length > 0 ? true : false;
  },
  data() {
    return {
      theInvoice: null,
      isOnCreateRefundMode: false,
      isDownloadFileModalOpen: false,
    };
  },
  watch: {
    theInvoice() {
      if (typeof this.theInvoice.User != "undefined") {
        this.selectedContactUser = this.theInvoice.User?.phone;
      }
    },
  },
  methods: {
    sendRefundRequest() {
      this.isOnCreateRefundMode = false;
    },
    updateLineInfo(updatedLine) {
      console.log(updatedLine);
    },
    createNewRefund() {
      this.isOnCreateRefundMode = true;
    },
    cancelNewRefund() {
      this.isOnCreateRefundMode = false;
    },
    async printInvoice() {
      try {
        // const response = await this.$store.dispatch(
        //   "Orders/printDelivery",
        //   docNum
        // );
        console.log("downloadDeliveryFile response:");
        // console.log(response);
        this.isDownloadFileModalOpen = true;
      } catch (error) {
        console.log("error printDelivery from Orders.vue:");
        console.log(error);
      }
    },
    changeTheDocShippingCode(newDocShippingCode) {
      this.newDocShippingCode = newDocShippingCode;
      console.log(this.newDocShippingCode);
    },
    changeTheDocReference(newDocReference) {
      this.newDocReference = newDocReference;
      console.log(this.newDocReference);
    },
    ...mapActions({
      toggleFavorite: "Parts/toggleFavorite",
      removeOrderLine: "Orders/removeOrderLine",
      updateOrderLine: "Orders/updateOrderLine",
    }),
  },
  computed: {
    ...mapGetters({
      getParts: "Parts/getParts",
      getUserPhone: "AuthModule/getUserPhone",
      getUserEmail: "AuthModule/getUserEmail",
      getCustData: "AuthModule/getCustomerData",
      isLoading: "isLoading",
    }),
  },
};
</script>

<style scoped lang="scss">
.singel-doc-page {
  // padding-bottom: 50px;
}

@media (max-width: 601px) {
  .singel-doc-page {
    padding-right: 14px;
    padding-left: 14px;
  }
}

.modal-content {
  font-size: $mediumTextSize;
  color: $modalContentColor;
  font-weight: 400;
  line-height: 21.33px;
  text-align: center;
  margin-top: -20px;

  .email {
    color: #36b079;
  }
}
.invoice-butttons-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.send-invoice {
  display: inline-flex;
  align-items: center;
  border: 1px solid $borderColor;

  font-size: $smallTextSize;
  background: $customerMainColor;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: right;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 15px;
  padding: 25px;
  color: white;
  max-width: max-content;

  border: i {
    margin-right: 10px;
  }
  i:lang(en) {
    padding-right: 10px;
    transform: rotate(180deg);
  }
}

.cancel-invoice,
.close-modal-btn {
  display: inline-flex;
  align-items: center;
  border: 1px solid $borderColor;
  background: $primaryBackgroundColor;

  font-size: $smallTextSize;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: right;
  border-radius: 15px;
  padding: 25px;
  color: black;
  max-width: max-content;

  border: i {
    margin-right: 10px;
  }
  i:lang(en) {
    padding-right: 10px;
    transform: rotate(180deg);
  }
}

.close-modal-btn {
  padding: 25px 40px;
}
</style>
