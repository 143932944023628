import Api from "./Api";

export default {
  getAgentListCounts() {
    return Api().get("getAgentListCounts");
  },
  getAgentsList() {
    return Api().get("control-panel-agents-list");
  },
  getDistLinesList() {
    return Api().get("control-panel-distlines-list");
  },
  updateNewAgentMonthlyGoal(obj) {
    return Api().put("updateNewAgentMonthlyGoal", obj);
  },
  getFiltersList() {
    return Api().get("control-panel-filters-list");
  },
};
