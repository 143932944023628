<template>
  <div>
    <BaseSideBar>
      <div id="loginform">
        <h1>{{ $t("LoginPage.loginFormTitle") }}</h1>
        <div v-if="!isOtpFieldOpen" class="context">
          {{ $t("LoginPage.welcome") }}
        </div>
        <div class="context" v-else>{{ $t("LoginPage.enterCode") }}</div>
        <label v-if="!isOtpFieldOpen">{{
          $t("LoginPage.enterPhoneNumberOrMail")
        }}</label>
        <label v-else>{{ userInput }}</label>
        <v-form v-model="form" @submit.prevent>
          <div>
            <v-text-field
              v-if="!isOtpFieldOpen"
              :disabled="isOtpFieldOpen"
              type="text"
              id="userEmail"
              clearable
              :placeholder="$t('LoginPage.userInputPlaceHolder')"
              v-model="userInput"
              :rules="[required]"
              @keyup.enter="submitedData"
              tabindex="1"
            />
            <v-slide-y-reverse-transition hide-on-leave>
              <div v-if="isOtpFieldOpen" class="otp-input-container">
                <v-card color="black" variant="outlined" class="mb-5">
                  <v-locale-provider locale="en">
                    <v-otp-input
                      :height="'85px'"
                      v-model="enterdOtp"
                      length="4"
                      @keyup.enter="submitedData"
                    ></v-otp-input>
                  </v-locale-provider>
                </v-card>
              </div>
            </v-slide-y-reverse-transition>
          </div>
          <Alert
            v-if="invalidValue"
            mode="warning"
            :alertContent="$t('missingPhoneOrMail.LoginPage')"
          ></Alert>
          <Alert
            v-else-if="authFaild"
            mode="warning"
            :alertContent="errorMessage"
          ></Alert>
          <div class="login-btn-container">
            <div :style="{ width: '100%' }">
              <v-btn
                @click="submitedData"
                :loading="loading"
                :icon="'mdi-cellphone-arrow-down-variant'"
                class="btn"
                tabindex="3"
              >
                <v-slide-x-transition
                  v-if="!isOtpFieldOpen"
                  :leave-absolute="true"
                  :hide-on-leave="true"
                >
                  <div>
                    <v-icon
                      :icon="'mdi-cellphone-arrow-down-variant'"
                      :color="'white'"
                    />
                    {{ $t("LoginPage.loginButton") }}
                  </div>
                </v-slide-x-transition>
                <v-slide-x-reverse-transition
                  v-else
                  :leave-absolute="true"
                  :hide-on-leave="true"
                >
                  <div class="enter">
                    <v-icon :icon="'mdi-arrow-left-circle'" :color="'white'" />
                    {{ $t("LoginPage.eneter") }}
                  </div>
                </v-slide-x-reverse-transition>
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>
    </BaseSideBar>
  </div>
</template>

<script>
import BaseSideBar from "./BaseSideBar.vue";
import AuthService from "@/services/AuthService";
import Alert from "../helpers/Alert.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseSideBar,
    Alert,
  },
  data() {
    return {
      userInput: "",
      enterdOtp: "",
      showIconPassShow: true,
      loading: false,
      authFaild: false,
      isPassShow: false,
      // loadingData: false,
      invalidValue: false,
      errorMessage: "",
      resetPassModalIsVisible: false,
      form: false,
      isOtpFieldOpen: false,
      resendTimeout: 60 * 5,
    };
  },
  watch: {
    userInput(val) {
      if (val === "") {
        this.invalidValue = false;
        this.authFaild = false;
      }
    },
    enterdOtp(val) {
      if (val === "") {
        this.invalidValue = false;
        this.authFaild = false;
      }
    },
  },
  methods: {
    startResendTimer() {
      const timer = setInterval(() => {
        this.resendTimeout--;
        if (this.resendTimeout === 0) {
          clearInterval(timer);
          this.resendTimeout = 60 * 5;
          this.isOtpFieldOpen = false;
          this.invalidValue = false;
          this.authFaild = false;
        }
      }, 1000);
    },
    required(v) {
      return !!v || this.$t("LoginPage.mandatoryFieldError");
    },
    counter() {
      this.$store.state.counter++;
    },
    async submitedData() {
      this.invalidValue = false;
      this.authFaild = false;
      if (!this.form) return;

      if (this.userInput && this.enterdOtp === "" && !this.isOtpFieldOpen) {
        this.loading = true;
        try {
          const response = await AuthService.login({
            phoneNumber: this.userInput,
          });

          if (response.status === 200) {
            this.isOtpFieldOpen = true;
            this.startResendTimer();
          } else throw new Error();
          return;
        } catch (error) {
          console.log(error);
          this.errorMessage =
            error?.response?.data?.error ??
            error?.response?.data?.message ?? this.$t("LoginPage.errorLoginServerSide");
            console.log(this.errorMessage)
          this.authFaild = true;
          return;
        } finally {
          this.loading = false;
        }
      }

      if (this.isOtpFieldOpen && this.enterdOtp) {
        this.loading = true;
        try {
          const response = await AuthService.verifyOtp({
            phoneNumber: this.userInput,
            otpCode: this.enterdOtp,
          });

          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("token", response.data.token);

          this.$i18n.locale = response.data.user.language;

          await this.$store.dispatch("AuthModule/login", {
            user: response.data.user,
            token: response.data.token,
          });

          console.log("user date:");
          console.log(response.data.user);
          console.log("");

          const getBigDataSucceeded = await AuthService.getBigData();
          console.log("get big data Succeeded?");
          console.log(getBigDataSucceeded);
          this.authFaild = false;
          this.$router.replace("/");
        } catch (error) {
          this.authFaild = true;
          this.errorMessage =
            error?.response?.data?.error ??
            this.$t("LoginPage.errorLoginServerSide");
        } finally {
          this.loading = false;
        }
      } else if (this.isOtpFieldOpen && !this.enterdOtp) {
        this.authFaild = true;
        this.errorMessage = this.$t("LoginPage.emptyOtpInput");
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
    }),
  },
};
</script>

<style lang="scss">
.side-nav-login {
  .v-otp-input {
    padding-bottom: 0px;
    justify-content: flex-end;
  }
  .v-otp-input__content {
    max-width: 100%;
    padding: 5px 0px;
  }
  .v-field:has(#userEmail) .v-field__outline {
    display: none;
  }
  .v-card {
    border: unset;
  }

  .v-field__outline {
    border-radius: 10px !important;
  }

  .v-field__overlay {
    background: white !important;
  }
  .v-field__input {
    border: 1px solid #ececec !important;
    border-radius: 10px;
  }
  .v-btn__content {
    width: 310px;
  }

  .v-input__control {
    padding-left: unset;
    .v-field {
      padding-left: unset;
    }
  }
  input {
    height: 100% !important;
    font-weight: 700;
  }
}
.v-form {
  width: 100%;
}
#userEmail {
  padding-bottom: 5px;
  color: $orderLineColor;
}
</style>
<style scoped lang="scss">
.password-input-container {
  position: relative;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 22px;
    cursor: pointer;
    color: grey;
  }
}
.login-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .btn {
    height: 62px;
    width: 100%;
    border-radius: 15px;
    background: $primaryTextColor;
    color: white;
    font-weight: 400;
    line-height: 21px;
  }
  .enter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}
a {
  color: $secondaryTextColor;
  cursor: pointer;
}
#loginform {
  // padding: 20px 30px;
}
#forgot-password-container {
  margin-top: 20px;
}

h1 {
  font-size: 72px;
  background: -webkit-linear-gradient(
    235.26deg,
    #6d1106 0%,
    rgba(109, 17, 6, 0.7490196078) 40.5%,
    #f57354 94%,
    #fbca90 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: $h1TextTitleSize;
  margin-bottom: 0px;
  margin-top: 0px;
}
.context {
  font-size: $mediumTextSize;
  color: $orderLineColor;
  margin-bottom: 20px;
}
label {
  font-size: $mediumTextSize;
  font-weight: 700;
}

.svg {
  position: absolute;
  bottom: 0px;
  z-index: 0;
}

@media only screen and (max-width: 900px) {
  .material-icons {
    right: 320px;
  }
}
</style>
