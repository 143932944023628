import ControlPanelService from "../../services/ControlPanelService";

export default {
  namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
  state() {
    return {
      agentsList: [],
      distLinesList: [],
      filters: {
        agents: [],
        customers: [],
        distLines: [],
        statuses: [],
      },
    };
  },
  mutations: {
    setAgentsList(state, agentsList) {
      state.agentsList = agentsList;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setDistLinesList(state, distLinesList) {
      state.distLinesList = distLinesList;
    },
    updateAgentMonthlyGoal(state, data) {
      const { newMonthlyGoal, agent: agentId } = data;
      const foundAgentIndex = state.agentsList.findIndex(
        (agent) => agent.agent === agentId
      );
      if (foundAgentIndex !== -1) {
        state.agentsList[foundAgentIndex].agnetGoalSalesInNls = newMonthlyGoal;
      }
    },
  },
  actions: {
    async getFilters(context) {
      try {
        if (context.state.filters.agents.length > 0) {
          return;
        }
        const response = await ControlPanelService.getFiltersList();
        context.commit("setFilters", response.data);
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async agentsList(context) {
      const response = await ControlPanelService.getAgentsList();
      context.commit("setAgentsList", response.data);
    },
    async distLinesList(context) {
      const response = await ControlPanelService.getDistLinesList();
      context.commit("setDistLinesList", response.data);
    },
    async updateNewAgentMonthlyGoal(context, data) {
      context.dispatch("startLoading", null, { root: true });
      try {
        const response = await ControlPanelService.updateNewAgentMonthlyGoal(
          data
        );
        if (response.status === 200) {
          context.dispatch("stopLoading", null, { root: true });
          context.commit("updateAgentMonthlyGoal", data);
          context.dispatch(
            "ShowToast",
            {
              message: "השינויים נשמרו בהצלחה",
              status: "success",
            },
            { root: true }
          );
          return true;
        } else throw new Error();
      } catch (error) {
        console.log(error);
        context.dispatch("stopLoading", null, { root: true });
        context.dispatch(
          "ShowToast",
          {
            message: "שגיאה בעדכון ערך חדש",
            status: "error",
          },
          { root: true }
        );
        return false;
      }
    },
  },
  getters: {
    getAgentsList(state) {
      return state.agentsList;
    },
    getDistLinesList(state) {
      return state.distLinesList;
    },
    getFilters(state) {
      return state.filters;
    },
  },
};
