<template>
  <div>
    <WrapperTableLine
      class="customer-wrapper-line"
      :isBtnExpand="isBtnExpand"
      :hightExpand="`${modalHeight}px`"
    >
      <div
        style="width: 100%"
        class="d-flex align-center text-center justify-space-between"
      >
        <TableInfoLine class="table-line flex-2" :value="custDes" />
        <TableInfoLine
          class="table-line"
          :value="customer?.isActive ? 'פעיל' : 'לא פעיל'"
        />
        <TableInfoLine class="table-line" :value="customer.DistLine.linedes" />
        <TableInfoLine class="table-line" :value="lineDays.join(', ')" />
        <TableInfoLine class="table-line" :value="customer.Agent.agentname" />
        <TableInfoLine
          class="table-line"
          :value="customer.lastOrderDate ? lastOrderDateFormat : 'אין הזמנות'"
        />
        <TableInfoLine
          class="table-line"
          :value="`₪${formatPrice(customer.balance)} ${
            customer.balance < 0 ? 'זכות' : ''
          }`"
        />
        <v-icon
          @mouseover="changeIcon('hover')"
          @mouseleave="changeIcon('unhover')"
          @click="openInvoiceTableModal"
          color="#801512"
          :disabled="!customer?.Deliveries?.length > 0"
          :icon="icon"
        />
        <!-- <v-btn variant="plain" active flat class="mr-5">
          <v-icon color="#801512" size="25"> mdi-cellphone-check </v-icon>
          <v-tooltip activator="parent" location="top" text="לקוח באפליקציה"
        /></v-btn> -->
        <v-chip v-if="customer.isInApp" color="#801512" class="mr-5 ml-5"
          >לקוח באפליקציה</v-chip
        >
        <v-chip
          v-else-if="isCustomerHasOrderForTomorrow"
          color="#801512"
          class="mr-5 ml-5"
        >
          הוזמן למחר</v-chip
        >
        <div v-else style="width: 100px" class="mr-5 ml-5" />
      </div>
      <TableActions
        :isCustCanCreateAnOrder="Boolean(customer.isActive)"
        :agnetInfo="customer.Agent"
        :customerInfo="customer"
        :cust="customer.cust"
        :id="cust"
        :isBtnExpand="isBtnExpand"
        :backgroundColor="'#36b079'"
        @close-expand="$emit('close-expand')"
        @expand-btn="expandBtn"
        @setHeight="setHeight"
        mode="customer"
      />
    </WrapperTableLine>
    <InvoiceTableModal
      v-if="isInvoiceTableModalOpen"
      :invoicesList="customer?.Deliveries"
      :custDes="customer.custdes"
      :openModal="isInvoiceTableModalOpen"
      @closeModal="isInvoiceTableModalOpen = false"
    />
  </div>
</template>

<script>
import TableActions from "./TableActions.vue";
import TableInfoLine from "./TableInfoLine.vue";
import WrapperTableLine from "./WrapperTableLine.vue";
import InvoiceTableModal from "../UI/modals/InvoiceTableModal.vue";
import { getNextDays } from "../../dates";
import moment from "moment";
import { formatPrice } from "../../utilities";

export default {
  emits: ["set-current-expand-btn", "close-expand"],
  components: {
    WrapperTableLine,
    TableInfoLine,
    TableActions,
    InvoiceTableModal,
  },
  props: {
    customer: {
      type: Object,
    },
    cust: {
      type: Number,
    },
    currentExpandBtn: {
      type: Number,
    },
    tableLineBackground: {
      type: String,
    },
  },
  data() {
    return {
      icon: "mdi mdi-eye-outline",
      isInvoiceTableModalOpen: false,
      modalHeight: 0,
    };
  },
  computed: {
    isCustomerHasOrderForTomorrow() {
      const tomorrow = moment().add(1, "days").format("DD/MM/YYYY");
      const lastOrderDate = moment(this.customer.lastOrderSenddate).format(
        "DD/MM/YYYY"
      );
      return lastOrderDate === tomorrow;
    },
    lastOrderDateFormat() {
      return moment(this.customer.lastOrderDate).format("DD/MM/YYYY");
    },
    custDes() {
      return this.customer.custdes;
    },
    lineDays() {
      return getNextDays(this.customer.DistLine);
    },
    isBtnExpand() {
      if (this.currentExpandBtn === this.customer.cust) return true;
      return false;
    },
    alignWrapper() {
      if (this.isBtnExpand) return "start";
      return "center";
    },
  },
  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
    openInvoiceTableModal() {
      this.isInvoiceTableModalOpen = !this.isInvoiceTableModalOpen;
    },
    changeIcon(mode) {
      switch (mode) {
        case "hover":
          this.icon = "mdi mdi-eye";
          break;
        case "unhover":
          this.icon = "mdi mdi-eye-outline";
          break;
      }
    },
    setHeight(setHeight) {
      this.modalHeight = setHeight + 30;
    },
    expandBtn() {
      if (!this.isBtnExpand) {
        this.$emit("set-current-expand-btn", this.customer.cust);
      } else {
        this.$emit("set-current-expand-btn", false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.table-line {
  flex: 1;
  min-width: 120px;
  max-width: 140px;
}

.customer-wrapper-line {
  display: flex;
  justify-content: space-between;
  align-items: v-bind(alignWrapper);
  background: v-bind(tableLineBackground);
}

.v-icon {
  cursor: pointer !important;
}

@media only screen and (max-width: 1440px) {
  .customer-wrapper-line {
    width: fit-content;
  }

  .table-line {
    min-width: 200px;
  }
}
</style>
