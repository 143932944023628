<template>
  <div
    class="product"
    :style="{ maxWidth: $vuetify.display.mobile ? '100%' : '350px' }"
  >
    <span class="label" :class="{ outOfStock: !product.AvailableInStock }">{{
      product.AvailableInStock
        ? $t("Products.productItem.AvailableInStock")
        : $t("Products.productItem.outOfStock")
    }}</span>
    <div class="grid">
      <div class="d-flex flex-column ga-5">
        <div class="detail">
          <label>{{ $t("Products.productItem.sku") }}</label>
          <div>{{ product?.sku }}</div>
        </div>
        <div class="detail">
          <label>{{ $t("Products.productItem.category") }}</label>
          <div>{{ product.category }}</div>
        </div>
      </div>
      <img :src="product.image" alt="" />
    </div>
    <div class="detail">
      <label>{{ $t("Products.productItem.description") }}</label>
      <div>
        {{ product.description }}
      </div>
      <div @click="$emit('moreInfo', product)" class="more-info">
        <v-icon :size="'15px'" :color="'black'" :icon="'mdi-information'" />
        <span>{{ $t("Products.productItem.moreInfo") }}</span>
      </div>
    </div>
    <div class="d-flex flex-row justify-space-between">
      <div :style="{ minWidth: '185px' }">
        <QuantityButtons
          :TheCounter="product.quant"
          @updateCounter="updateProduct"
        />
      </div>
      <Prices
        :discountPercent="product?.discountPercent"
        :discountPrice="product?.discountPrice"
        :originPrice="product?.originPrice"
      />
    </div>
  </div>
</template>

<script>
import QuantityButtons from "../helpers/QuantityButtons.vue";
import Prices from "./Prices.vue";
export default {
  emits: ["updateProduct", "moreInfo"],
  components: {
    QuantityButtons,
    Prices,
  },
  props: {
    product: {
      type: Object,
    },
  },
  methods: {
    updateProduct(quantity) {
      this.$emit("updateProduct", { quantity, product: this.product });
    },
  },

  computed: {
    isLongPrice() {
      if (("" + this.product.discountPrice).length > 3) return false;
      else return true;
    },
  },
};
</script>

<style scoped lang="scss">
.product {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.label {
  width: fit-content;
  border-radius: 50px;
  padding: 0px 10px;
  background: #e6f6ef;
  color: #36b079;
  position: relative;
  padding-right: 20px;
  &::before {
    content: "";
    height: 6px;
    width: 6px;
    position: absolute;
    top: 50%;
    background-color: #36b079;
    right: 9px;
    transform: translateY(-50%);
    border-radius: 50%;
  }
}
.outOfStock {
  color: #d13257;
  background: #f2e3e7;
  &::before {
    background-color: #d13257;
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.detail {
  label {
    font-weight: 700;
  }
  div {
    line-height: 21px;
    color: $orderLineColor;
  }
}
img {
  object-fit: contain;
  width: 150px;
}
.more-info {
  cursor: pointer;
  margin-top: 10px;
  span {
    text-decoration: underline;
    color: #434343;
  }
}
</style>