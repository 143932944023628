<template>
  <div class="overlay" scroll="no" @click="$emit('forceModalClose')">
    <div class="credit-modal-container">
      <div class="credit-icon-container">
        <i class="material-icons right">widgets </i>
      </div>
      <div @click="$emit('forceModalClose')" class="close-modal">X</div>
      <div class="inner-container">
        <div class="credit-header">
          <p>פורטל הזמנות נירעותק</p>
          <P> גירסה {{ this.$store.getters["getAppVersion"] }}</P>
        </div>
        <div class="credit-text">
          פורטל לקוחות נירעותק פותח בשיתוף בין Rotlein IT, חברת יישום ופיתוח
          מערכות ERP מתקדמות לעסקים,
          <br />
          יחד עם חברת dolimo, המתמחה בעיצוב ופיתוח אתרים אפליקטייבים המותאמים
          אישית לצורכי הלקוח.
          <BR />
          ניתן להתרשם מעבודות נוספות באתרי החברות.
        </div>
        <div class="imgs">
          <a href="https://dolimo.co.il/"
            ><img class="dolimo-img" :src="dolimoLogo" alt="dolimo logo" />
            <p>לאתר החברה</p>
          </a>
          <a href="https://www.rotlein.co.il/">
            <img :src="rotleinLogo" alt="rotlein logo" class="rtl-img" />
            <p>לאתר החברה</p>
          </a>
        </div>
      </div>
      <div class="dolimo"></div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["forceModalClose"],
  computed: {
    rotleinLogo() {
      return "https://dolimo.co.il/wp-content/uploads/2022/09/rtl-logo-1.png";
    },
    dolimoLogo() {
      return "https://dolimo.co.il/wp-content/uploads/2020/10/credit.svg";
    },
  },
};
</script>

<style  scoped lang="scss">
$iconContainerSize: 60px;
p {
  margin: unset;
  color: rgb(175, 175, 175);
}
h3 {
  font-size: $largTextSize;
  font-weight: bold;
  margin: unset;
}
.credit-icon-container {
  background: $primaryBackgroundButtonColor;
  height: $iconContainerSize;
  width: $iconContainerSize;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: - calc($iconContainerSize / 2);
  border: 3px solid white;
  i {
    margin: unset;
    color: white;
    font-size: 24px;
  }
}
.close-modal {
  width: 40px;
  height: 40px;
  background: #cfcfcf;
  color: #717171;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  left: 20px;
  cursor: pointer;
}
.overlay {
  background-color: #00000078;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 90;
  display: flex;
  justify-content: center;
  align-items: center;
}
.credit-modal-container {
  padding: $iconContainerSize 30px 30px 30px;
  width: fit-content;
  height: fit-content;
  background: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
}
.inner-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  .credit-text {
    text-align: justify;
    text-align-last: center;
  }
  .credit-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .imgs {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
    p {
      text-align: center;
      font-size: $smallTextSize;
      text-decoration: underline;
      color: rgb(129, 102, 250);
      &:hover {
        color: black;
      }
    }
  }
  .dolimo-img {
    width: 100px;
  }
  .rtl-img {
    width: 120px;
  }
  img {
    width: 250px;
  }
  .credit-text {
    font-size: $mediumTextSize;

    width: 450px;
    text-align: center;
    color: $secondaryTextColor;
  }
}
@media only screen and (max-width: 768px) {
  h3 {
    font-size: 30px;
  }

  .dolimo-img {
    width: 120px !important;
  }
  .rtl-img {
    width: 140px !important;
  }
  img {
    width: 200px !important;
  }
  .credit-modal-container {
    width: 95vw;
  }
  .inner-container {
    .credit-text {
      width: 95%;
    }
  }
  .close-modal {
    // display: flex;
  }
}
</style>