<template>
  <div class="product-detail-item">
    <label class="product-detail-item__label">{{ label }}</label>
    <div
      :class="[
        'product-detail-item__value',
        { 'product-detail-item__value--label': isLabel }
      ]"
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    isLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.product-detail-item {
  &__label {
    font-weight: 700;
  }

  &__value {
    color: var(--order-line-color, #333);

    &--label {
      display: inline-block;
      border-radius: 50px;
      padding: 0 10px;
      background: #e6f6ef;
      color: #36b079;
    }
  }
}
</style>