import AgentRequestSevrice from "@/services/AgentRequestSevrice";
const moment = require("moment");

const updateAgentRequestsAgents = (state, agentRequests) => {
  const uniqueAgents = {};

  agentRequests.forEach((agentReq) => {
    const agentId = agentReq?.Agent?.agent;
    if (!uniqueAgents[agentId]) {
      uniqueAgents[agentId] = {
        id: agentId,
        name: agentReq?.Agent?.agentname,
      };
    }
  });

  state.agentRequestAgents = Object.values(uniqueAgents);
};

const sortAgentRequests = (agentRequests) => {
  return agentRequests.sort((a, b) => {
    // First priority: accepctByOther is true
    if (a.accepctByOther && !b.accepctByOther) return -1;
    if (!a.accepctByOther && b.accepctByOther) return 1;

    // Second priority: rejectedByUser is true
    if (a.rejectedByUser && !b.rejectedByUser) return -1;
    if (!a.rejectedByUser && b.rejectedByUser) return 1;

    // Last priority: false accepctByOther and rejectedByUser
    if (
      !a.accepctByOther &&
      !a.rejectedByUser &&
      (b.accepctByOther || b.rejectedByUser)
    )
      return 1;
    if (
      !b.accepctByOther &&
      !b.rejectedByUser &&
      (a.accepctByOther || a.rejectedByUser)
    )
      return -1;

    // If all conditions are the same, maintain original order
    return 0;
  });
};

const updateAgentRequestsCustomers = (state, agentRequests) => {
  const uniqueCustomers = {};

  agentRequests.forEach((agentReq) => {
    const customerId = agentReq.cust;
    if (!uniqueCustomers[customerId]) {
      uniqueCustomers[customerId] = {
        id: customerId,
        name: agentReq?.Customer?.custname,
      };
    }
  });

  state.agentRequestCustomers = Object.values(uniqueCustomers);
};

// const updateAgentRequestsStatuses = (state, orders) => {
//   const ordersStatuses = {};

//   orders.forEach((order) => {
//     const statusId = order.Status.status;
//     if (!ordersStatuses[statusId]) {
//       ordersStatuses[statusId] = {
//         id: statusId,
//         name: order.Status.statusname,
//       };
//     }
//   });

//   state.ordersStatuses = Object.values(ordersStatuses);
// };

export default {
  namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
  state() {
    return {
      agentRequests: [],
      agentRequestStatuses: [],
      agentRequestCustomers: [],
      agentRequestAgents: [],
      openAgentRequests: 0,
    };
  },
  mutations: {
    addAgentRequest(state, agentRequest) {
      state.agentRequests.unshift(agentRequest);
    },
    addAgentRequests(state, agentRequests) {
      for (let i = 0; i < agentRequests.length; i++) {
        const foundAgentRequstIndex = state.agentRequests.findIndex(
          (agentRequest) => agentRequest.id === agentRequests[i].id
        );
        if (foundAgentRequstIndex === -1) {
          state.agentRequests.unshift(agentRequests[i]);
        } else {
          state.agentRequests[foundAgentRequstIndex] = agentRequests[i];
        }
      }
    },
    setAgentRequests(state, agentRequests) {
      state.agentRequests = agentRequests;
      updateAgentRequestsCustomers(state, agentRequests);
      updateAgentRequestsAgents(state, agentRequests);
    },
    setOpenAgentRequests(state, data = null) {
      if (data) {
        state.openAgentRequests = data;
      } else {
        state.openAgentRequests = state.agentRequests.filter(
          (request) =>
            request.isAccepct === false && request.isRejection === false
        ).length;
      }
    },
    setAgentRequest(state, request) {
      const { requestId, mode, getters } = request;
      const foundRequestIndex = getters.getIndexOfAgentRequestById(requestId);
      if (foundRequestIndex !== -1) {
        const foundRequest = state.agentRequests[foundRequestIndex];
        foundRequest.isAccepct = false;
        foundRequest.isRejection = false;
        if (foundRequest[mode] !== undefined || foundRequest[mode] !== null)
          foundRequest[mode] = true;
        if (mode === "isRejection") {
          if (request?.userObj)
            foundRequest.rejectedByUser = request.userObj.name;
          else foundRequest.rejectedByUser = "";
          state.openAgentRequests--;
        } else if (mode === "isAccepct") {
          if (request?.userObj)
            foundRequest.accepctByOther = request.userObj.name;
          else foundRequest.accepctByOther = "";
          state.openAgentRequests--;
        } else if (mode === "clear") {
          foundRequest.rejectedByUser = "";
          foundRequest.accepctByOther = "";
          state.openAgentRequests++;
        }
      }
    },
  },
  actions: {
    async agentRequestsIntervalUpdate(context) {
      try {
        const agentRequests = await AgentRequestSevrice.getAgentRequests(
          moment().subtract(24, "hours").toDate()
        );
        await context.dispatch("addAgentRequests", agentRequests.data);
        return true;
      } catch (expection) {
        console.log(expection);
        return false;
      }
    },
    updateOpenAgentRequests(context, data) {
      context.commit("setOpenAgentRequests", data);
    },
    async updateAgentRequests(context, data) {
      var sorted = sortAgentRequests(data);
      context.commit("setAgentRequests", sorted);
      context.commit("setOpenAgentRequests");
    },
    async addAgentRequests(context, data) {
      var sorted = sortAgentRequests(data);
      context.commit("addAgentRequests", sorted);
      context.commit("setOpenAgentRequests");
    },
    async clearAgentRequest(context, requestId) {
      try {
        const agentRequest = {
          isAccepct: false,
          isRejection: false,
          rejectedByUser: "",
          accepctByOther: "",
          requestId,
        };
        await AgentRequestSevrice.updateAgnetRequest(agentRequest);
        context.commit("setAgentRequest", {
          requestId,
          mode: "clear",
          getters: context.getters,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async rejectAgentRequest(context, { requestId, userObj }) {
      try {
        const agentRequest = {
          isRejection: true,
          rejectedByUser: userObj.name,
          requestId,
        };
        await AgentRequestSevrice.updateAgnetRequest(agentRequest);
        context.commit("setAgentRequest", {
          requestId,
          mode: "isRejection",
          userObj,
          getters: context.getters,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async acceptAgentRequest(context, { requestId, userObj }) {
      try {
        const agentRequest = {
          isAccepct: true,
          accepctByOther: userObj.name,
          requestId,
        };
        await AgentRequestSevrice.updateAgnetRequest(agentRequest);
        console.log("acceptAgentRequest");
        context.commit("setAgentRequest", {
          requestId,
          mode: "isAccepct",
          userObj,
          getters: context.getters,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async addAgentRequest(context, obj) {
      try {
        var agentRequest = await AgentRequestSevrice.createAgnetRequest(obj);
        context.commit("addAgentRequest", agentRequest.data);
        context.dispatch(
          "ShowToast",
          {
            message: "הבקשה נוספה בהצלחה",
            status: "success",
          },
          { root: true }
        );
      } catch (err) {
        context.dispatch(
          "ShowToast",
          {
            message: "הייתה בעיה בעת הוספת הבקשה",
            status: "error",
          },
          { root: true }
        );
        console.log(err);
      }
    },
  },
  getters: {
    getAgentRequests(state) {
      return state.agentRequests;
    },
    getAgentRequestCustomers(state) {
      return state.agentRequestCustomers;
    },
    getAgentRequestAgents(state) {
      return state.agentRequestAgents;
    },
    getAgentRequestStatuses(state) {
      return state.agentRequestStatuses;
    },
    getOpenAgentRequests(state) {
      return state.openAgentRequests;
    },
    getIndexOfAgentRequestById: (state) => (id) => {
      return state.agentRequests.findIndex((request) => request.id === id);
    },
  },
};
