<template>
  <div class="border-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
.border-wrapper {
  /* border-top: 1px solid #ececec;
  border-left: 1px solid #ececec; */
  border-top-left-radius: 20px;
  border-right: 0px;
  padding: 40px;
  padding-top: 0px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 601px) {
  .border-wrapper {
    border-top: unset;
    border-left: unset;
    border-top-left-radius: unset;
    border-right: unset;
    padding: unset;
    padding-top: unset;
    padding-bottom: unset;
  }
}
</style>
