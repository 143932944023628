<template>
  <TableWrapper
    mode="customer"
    :sumCount="customersList.length"
    title="כל הלקוחות"
    icon="mdi-account-group-outline"
  >
    <div class="customer-table-content" @scroll="handleScroll">
      <StickyTableHeader
        headerWidth="73%"
        :tableInfoLinesHeader="customerTableInfoLinesHeader"
        :showHeader="showHeader"
        :isMobile="isMobile"
      />
      <div class="d-flex flex-column ga-3 pa-5">
        <CustomerTableLine
          v-for="(customer, index) in customersList"
          :key="customer.cust"
          :cust="customer.cust"
          :customer="customer"
          :currentExpandBtn="currentExpandBtn"
          :tableLineBackground="
            (index + 1) % 2 !== 0 ? '#ffefdb' : 'transparent'
          "
          @close-expand="setCurrentExpandBtn"
          @set-current-expand-btn="setCurrentExpandBtn"
        />
      </div>
    </div>
  </TableWrapper>
  <v-pagination
    v-model="page"
    :active-color="'blue'"
    :length="numberOfPages"
    :total-visible="7"
  ></v-pagination>
</template>

<script>
import CustomerTableLine from "./CustomerTableLine.vue";
import TableWrapper from "./TableWrapper.vue";
import StickyTableHeader from "./StickyTableHeader.vue";
import { mapGetters } from "vuex";

export default {
  components: { CustomerTableLine, TableWrapper, StickyTableHeader },
  props: ["customersList", "count"],
  emits: ["getCustomersPagination"],
  data() {
    return {
      currentExpandBtn: -1,
      page: 1,
      showHeader: false,
      customerTableInfoLinesHeader: [
        this.$t(`customers.table.columns.customer`),
        this.$t(`customers.table.columns.customerTypeStatus`),
        this.$t(`customers.table.columns.distLine`),
        this.$t(`customers.table.columns.distLineDays`),
        this.$t(`customers.table.columns.agent`),
        this.$t(`customers.table.columns.lastOrder`),
        this.$t(`customers.table.columns.balance`),
      ],
    };
  },
  watch: {
    page() {
      var query = {
        ...this.$route.query,
        page: this.page,
      };

      this.$router.push({ path: this.$route.path, query: query });
    },
    "$route.query": {
      immediate: true,
      handler() {
        this.page = 1;
      },
    },
    "$route.query.page": {
      immediate: true,
      handler() {
        if (this.$route.query.page) {
          if (this.$route.query.page > this.numberOfPages) {
            this.page = this.numberOfPages;
          } else this.page = Number(this.$route.query.page);
        }
      },
    },
  },

  methods: {
    setCurrentExpandBtn(custId = -1) {
      this.currentExpandBtn = custId;
    },
    handleScroll(event) {
      this.showHeader = event.target.scrollTop > 0;
    },
  },
  computed: {
    numberOfPages() {
      if (this.count === 0) return 1;
      return Math.ceil(this.count / 30);
    },
    ...mapGetters({
      isMobile: "isMobile",
    }),
  },
};
</script>

<style scoped lang="scss">
.customer-table-content {
  position: relative;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
