<template>
  <modal-container
    :gap="'0px'"
    :borderRadius="'20px'"
    :padding="'20px'"
    :paddingTop="'0px'"
    :maxWidth="'400px'"
  >
    <Backdrop
      v-if="openModal"
      @close-model="$emit('closeModal')"
      :bgColor="'#00000061'"
    >
    </Backdrop>

    <div class="content w-100">
      <div class="d-flex justify-space-between align-center">
        <span class="black font-18 font-weight-bold"> הוספת בקשת סוכן</span>
        <v-icon
          icon="mdi mdi-close-circle-outline"
          class="mb-2"
          color="#757575"
          @click="$emit('closeModal')"
        />
      </div>
      <div class="">
        <div class="new-value-warpper">
          <!-- <div class="font-weight-bold">בחר לקוח</div> -->
          <v-select
            v-model="selectedCustomer"
            :items="getUniqueCustomer"
            item-title="name"
            item-value="id"
            label="בחר לקוח"
            class="new-value"
            dense
            outlined
            variant="solo"
            rounded="lg"
          ></v-select>
          <v-textarea
            v-model="details"
            rounded="lg"
            variant="solo"
            label="פרטי הבקשה"
          ></v-textarea>
        </div>
      </div>
      <div>
        <v-btn
          @click="sendNewMonthlyGoal"
          class="acssept-btn"
          width="361px"
          height="50px"
        >
          שליחת הבקשה
        </v-btn>
      </div>
    </div>
  </modal-container>
</template>

<script>
import Backdrop from "../backdrop.vue";
import ModalContainer from "./modal-container.vue";

export default {
  components: { ModalContainer, Backdrop },
  emits: ["closeModal", "addAgentRequest"],
  data() {
    return {
      selectedCustomer: null,
      details: "",
    };
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    getUniqueCustomer: {
      type: Array,
    },
    agentName: {
      type: String,
    },
  },
  methods: {
    sendNewMonthlyGoal() {
      if (!this.selectedCustomer) {
        this.$store.dispatch("ShowToast", {
          message: "יש לבחור לקוח",
          status: "error",
        });
        return;
      }
      console.log(this.selectedCustomer);
      this.$emit("addAgentRequest", {
        cust: this.selectedCustomer,
        text: this.details,
        date: Date.now(),
      });
    },
  },
};
</script>
<style lang="scss">
.new-value-warpper .v-field__input {
  padding-top: 25px !important;
}
</style>
<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
}
.title-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  .icon-backgorund {
    background: $primaryBackgroundColor;
    padding: 15px;
    border-radius: 50%;
  }
}
.previous-value-warpper {
  width: 200px;
  text-align: start;
  .previous-value {
    background: $grayBackgroundColor;
    padding: 10px 15px;
    border-radius: 10px;
  }
}
.new-value-warpper {
  text-align: start;
  .new-value {
    // max-width: 200px !important;
  }
}
</style>
