<template>
  <div @keyup.enter="gotosearch">
    <div
      v-if="!isSearchHeader"
      class="d-flex justify-space-between align-center input-field-container"
      :style="{ minWidth: minWidth ? minWidth : 'unset' }"
    >
      <input
        :placeholder="placeholder"
        type="text"
        id="autocomplete-input"
        v-model="searchword"
      />
      <v-btn
        @click="gotosearch"
        height="inherit"
        flat
        class="d-flex search-btn"
      >
        <svg
          class="search-icon"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.958 16.9585L20.1663 20.1668"
            stroke="white"
            stroke-width="1.375"
            stroke-linecap="round"
          />
          <path
            d="M6.18717 2.99852C7.46805 2.25756 8.95518 1.8335 10.5413 1.8335C15.3508 1.8335 19.2497 5.73235 19.2497 10.5418C19.2497 15.3513 15.3508 19.2502 10.5413 19.2502C5.73186 19.2502 1.83301 15.3513 1.83301 10.5418C1.83301 8.95567 2.25708 7.46854 2.99803 6.18766"
            stroke="white"
            stroke-width="1.375"
            stroke-linecap="round"
          />
        </svg>
        <span class="mr-1" for="autocomplete-input">{{
          $t("BaseSearch.search")
        }}</span>
      </v-btn>
    </div>
    <div
      v-else
      class="d-flex justify-space-between align-center input-field-container-header"
      :class="mode"
    >
      <input
        :placeholder="placeholder"
        type="text"
        id="autocomplete-input"
        v-model="searchword"
      />
      <v-btn
        @click="gotosearch"
        height="inherit"
        flat
        class="d-flex search-btn"
        :class="[mode]"
      >
        <svg
          class="search-icon"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.958 16.9585L20.1663 20.1668"
            stroke="white"
            stroke-width="1.375"
            stroke-linecap="round"
          />
          <path
            d="M6.18717 2.99852C7.46805 2.25756 8.95518 1.8335 10.5413 1.8335C15.3508 1.8335 19.2497 5.73235 19.2497 10.5418C19.2497 15.3513 15.3508 19.2502 10.5413 19.2502C5.73186 19.2502 1.83301 15.3513 1.83301 10.5418C1.83301 8.95567 2.25708 7.46854 2.99803 6.18766"
            stroke="white"
            stroke-width="1.375"
            stroke-linecap="round"
          />
        </svg>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update-search-word"],
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    isSearchHeader: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: String,
    },
    mode: {
      type: String,
      default: "controlPanel",
    },
    isLocalSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchword: "",
    };
  },
  mounted() {
    if (this.$route.query.search) this.searchword = this.$route.query.search;
  },
  watch: {
    "$route.query"() {
      if (typeof this.$route.query.search === "undefined") this.searchword = "";
    },
  },
  methods: {
    gotosearch() {
      this.$store.commit("setIsShowDesktopHome", true);
      this.searchword.length > 0
        ? (this.$store.commit("setMenuToClose"),
          this.$store.commit("setMobileNavText", this.$t("BaseSearch.search")))
        : this.$store.commit("setMenuToOpen"); // close menu in mobile when typing in search input

      if (this.isLocalSearch) {
        this.$emit("update-search-word", this.searchword);
        return;
      }
      let query = { ...this.$route.params, ...this.$route.query };
      delete query.category;
      if (this.searchword.length >= 1) {
        query.search = this.searchword;
        query.page = 1;
        this.$router.push({ path: this.$route.path, query: query });
      } else {
        this.$router.push({ path: this.$route.path });
      }
    },
  },
};
</script>

<style scoped lang="scss">
input::placeholder {
  font-size: $mediumTextSize;
  font-weight: 400;
  line-height: 21.33px;
  text-align: right;
  color: $placeholderColor;
}

.input-field-container {
  border: 1px solid #ececec;
  border-radius: 50px;
  box-shadow: 0px 3px 6px #00000015;
  height: 66px;
  max-width: 744px;
  padding-right: 30px;
}

.input-field-container-header {
  border: 2px solid $controlPanelMainColor;
  border-radius: 40px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  height: 66px;
  min-width: 368px;
  padding-right: 20px;

  .search-btn {
    color: white;
    font-size: $mediumTextSize;
    padding: 10px;
    border-radius: 50px;
    min-width: 66px;
    margin-left: -3px;
  }
}

@media only screen and (max-width: 601px) {
  .input-field-container-header {
    min-width: unset;
  }

  input::placeholder {
    font-size: $smallTextSize;
  }
}

#autocomplete-input {
  font-size: $mediumTextSize;
  color: #a4a4a4;
  width: 80%;
}

.search-icon {
  height: 22px;
  width: 22px;
  margin: auto;
}

.search-btn {
  color: white;
  font-size: $mediumTextSize;
  letter-spacing: 0;
  border-radius: 30px 0px 0px 30px;
  min-width: 110px;

  span {
    font-size: $mediumTextSize;
    font-weight: 400;
    line-height: 21.33px;
    text-align: center;
  }
}

.search-btn.controlPanel {
  background: $controlPanelMainColor;
}

.search-btn.customers {
  background: $customerMainColor;
}

.search-btn.orders {
  background: $ordersMainColor;
}

.input-field-container-header.customers {
  border-color: $customerMainColor;
}
.input-field-container-header.orders {
  border-color: $ordersMainColor;
}
</style>
