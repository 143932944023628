<template>
  <div class="wrapper">
    <!-- {{counter > 99}} -->
    <v-btn class="plus" @click="changeCounter(1)"> +</v-btn>
    <v-slide-x-reverse-transition :leave-absolute="true" :hide-on-leave="true">
      <template v-if="counter > 0">
        <div class="quantity-wrapper d-flex ga-1">
          <input
            :style="{ width: counter > 99 ? '30px' : '25px' }"
            class="quantity"
            type="number"
            name="name"
            @blur="updateValue"
            v-model="counter"
          />
          <v-btn class="minus" @click="changeCounter(-1)"> - </v-btn>
        </div>
      </template>
    </v-slide-x-reverse-transition>

    <v-slide-x-transition :leave-absolute="true" :hide-on-leave="true">
      <template v-if="counter === 0">
        <div class="add-wrapper">
          <label>{{ TheTitle ?? $t("Products.productItem.addition") }}</label>
        </div>
      </template>
    </v-slide-x-transition>
  </div>
</template>

<script>
export default {
  props: {
    TheCounter: {
      type: Number,
    },
    TheTitle: {
      type: String,
    },
  },
  data() {
    return {
      counter: 0,
    };
  },
  mounted() {
    if (this.TheCounter) this.counter = this.TheCounter;
    else this.counter = 0;
  },
  watch: {
    TheCounter: {
      immediate: false,
      handler() {
        if (this.TheCounter) this.counter = this.TheCounter;
        else this.counter = 0;
      },
    },
  },
  emits: ["updateCounter"],
  methods: {
    updateValue() {
      this.$emit("updateCounter", this.counter);
    },
    changeCounter(int) {
      this.counter += int;
      !isNaN(this.counter) && this.counter > 0
        ? this.counter
        : (this.counter = 0);
      this.$emit("updateCounter", this.counter);
    },
  },
};
</script>

<style scoped lang="scss">
.hidden {
  visibility: hidden;
}
.wrapper {
  background: #f8f8f8;
  width: fit-content;
  padding: 5px 10px;
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 4px;

  .plus {
    border: 1px#36B079 solid;
    border-radius: 20px;
    height: 30px;
    font-size: 22px;
    box-shadow: unset;
    color: #36b079;
    font-weight: 700;
  }
  .quantity {
    text-align: center;
    width: 30px;
    height: 30px !important;
  }
  .minus {
    font-size: 22px;
    height: 30px;
    border-radius: 20px;
    box-shadow: 0px 1px 4px 0px #00000026;
  }
  label {
    color: #36b079;
  }
}
</style>
