<template>
  <div id="alert-container" :class="mode === null ? 'hide' : mode">
    <div>
      <div class="pre">{{ alertContent }}</div>
    </div>

    <div>
      <i class="tiny material-icons">info_outline</i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: true,
      default: null,
    },
    alertContent: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
#alert-container > div {
  display: flex;
  align-items: center;
}
#alert-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  grid-column-gap: 10px;
  align-items: center;
  width: fit-content !important;
  align-items: flex-start;
}

div#alert-container {
  padding: 10px 20px;
  width: 100% !important;
  margin: 10px 0;
  margin-top: 0px;
  border-radius: 5px;
  .pre {
    font-size: $smallTextSize;
    margin: 0;
    display: inline;
    // text-align: right !important;
    // white-space: pre;
  }
}
.info {
  background-color: $infoColor;
}

.warning {
  background-color: $warningColor;
}
</style>
