import Api from "./Api";

export default {
  getCustomers() {
    return Api().get("customers");
  },
  getCustomersPagination(obj) {
    return Api().post("customers-pagination", obj);
  },
  getActiveCustomers() {
    return Api().get("active-customers-count");
  },
};
