import Api from "./Api";

export default {
  getAgentRequests(dateFilter = null) {
    const query = dateFilter ? `agentRequests?date=${dateFilter}` : "agentRequests";
    return Api().get(query);
  },
  getAgentRequest(id) {
    return Api().get("agentRequest", id);
  },
  createAgnetRequest(AgnetRequest) {
    return Api().post("createAgnetRequest", AgnetRequest);
  },
  updateAgnetRequest(AgnetRequest) {
    return Api().post("updateAgnetRequest", AgnetRequest);
  },
  getActiveAgentRequests() {
    return Api().get("active-agent-requests-count");
  },
};
