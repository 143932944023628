<template>
  <div class="empty-cart">
    <img v-if="imageSrc" :src="imageSrc" alt="not found image" />
    <div class="empty-title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    imageSrc: {
      type: String,
      default: ""
    },
  },
};
</script>

<style scoped lang="scss">
.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  .empty-title {
    color: $primaryTextColor;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    margin-bottom: 32px;
  }

  img {
    max-width: 700px;
  }

  @media (max-width: 601px) {
    img {
      width: 80%;
    }
  }
}
</style>
