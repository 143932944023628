import OrderService from "../../services/OrderService";
import download from "downloadjs";
import store from "../../store";
import moment from "moment";

// const updateOrdersDistLines = (state, orders) => {
//   const uniqueDistLines = {};

//   orders.forEach((order) => {
//     const lineKey = order.Customer.DistLine.linekey;
//     if (!uniqueDistLines[lineKey]) {
//       uniqueDistLines[lineKey] = {
//         id: lineKey,
//         name: order.Customer.DistLine.linedes,
//       };
//     }
//   });

//   state.ordersDistLines = Object.values(uniqueDistLines);
// };

// const updateOrdersAgents = (state, orders) => {
//   const uniqueAgents = {};

//   orders.forEach((order) => {
//     const agentId = order.Customer.Agent.agent;
//     if (!uniqueAgents[agentId]) {
//       uniqueAgents[agentId] = {
//         id: agentId,
//         name: order.Customer.Agent.agentname,
//       };
//     }
//   });

//   state.ordersAgents = Object.values(uniqueAgents);
// };

// const updateOrdersCustomers = (state, orders) => {
//   const uniqueCustomers = {};

//   orders.forEach((order) => {
//     const customerId = order.Customer.cust;
//     if (!uniqueCustomers[customerId]) {
//       uniqueCustomers[customerId] = {
//         id: customerId,
//         name: order.Customer.custdes,
//       };
//     }
//   });

//   state.ordersCustomers = Object.values(uniqueCustomers);
// };

// const updateOrdersStatuses = (state, orders) => {
//   const ordersStatuses = {};

//   orders.forEach((order) => {
//     const statusId = order.Status.status;
//     if (!ordersStatuses[statusId]) {
//       ordersStatuses[statusId] = {
//         id: statusId,
//         name: order.Status.statusname,
//       };
//     }
//   });

//   state.ordersStatuses = Object.values(ordersStatuses);
// };

export default {
  namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
  state() {
    return {
      Orders: [],
      Cart: {},
      OpenOrders: 0,
      CartTotal: 0,
      ordersDistLines: [],
      numberOfOrders: 0,
      // ordersAgents: [],
      // ordersCustomers: [],
      // ordersStatuses: [],
      order: {},
    };
  },
  mutations: {
    orderCount(state, count) {
      state.numberOfOrders = count;
    },
    clearCart(state) {
      state.Cart.OrderLines = [];
    },
    setOrder(state, order) {
      state.order = order;
    },
    addProductToCart(state, product) {
      state.Cart.OrderLines.unshift({
        quant: product.quant,
        ordi: product.ordi,
        Part: { ...product },
      });
    },
    removeProductFromCart(state, productIndex) {
      state.Cart.OrderLines.splice(productIndex, 1);
    },
    updateProductInCart(state, obj) {
      var { productIndex, product } = obj;
      state.Cart.OrderLines[productIndex] = {
        quant: product.quant,
        ordi: product.ordi,
        Part: { ...product },
      };
    },
    //old nirotek code
    setOrders(state, orders) {
      const sortedOrders = orders.sort((a, b) => {
        return moment(b.duedate) - moment(a.duedate);
      });
      state.Orders = sortedOrders;
      // if need to update the order count when getting the pagesiongs
      // this.commit("Orders/setOpenOrders");
      // updateOrdersCustomers(state, orders);
      // updateOrdersAgents(state, orders);
      // updateOrdersDistLines(state, orders);
      // updateOrdersStatuses(state, orders);
    },
    setOrderLine(state, newOrderLine) {
      const index = state.Orders.findIndex(
        (ord) => ord.ord === newOrderLine.ord
      );
      console.log("the index");
      console.log(index);

      if (typeof index !== "undefined") {
        //state.Orders[index].OrderLines[newOrderLine.line - 1] = newOrderLine;
        for (let line of state.Orders[index].OrderLines) {
          // console.log(line);
          if (line.ordi === newOrderLine.ordi) {
            line = {
              ...newOrderLine,
            };
          }
        }
      }
      this.commit("Orders/setOpenOrders");
    },
    removeLine(state, data) {
      if (data.ord > 0) {
        const index = state.Orders.findIndex((ord) => ord.ord === data.ord);
        const ordiIndex = state.Orders[index].OrderLines.findIndex(
          (line) => line.ordi === data.ordi
        );
        state.Orders[index].OrderLines.splice(ordiIndex, 1);
        this.commit("Orders/setOpenOrders");
      } else {
        const cartIndex = state.Cart.OrderLines.findIndex(
          (line) => line.ordi === data.ordi
        );
        state.Cart.OrderLines.splice(cartIndex, 1);
        this.commit("Orders/setCartTotal");
      }
    },
    setCart(state, userObj) {
      state.Cart = userObj;
      this.commit("Orders/setCartTotal");
    },
    setCartTotal(state) {
      let total = 0;

      state.Cart.OrderLines.forEach((line) => {
        total += line.quant;
      });
      state.CartTotal = total;
      if (total === 0) {
        this.commit("setIsOpenMobileSideBar", false, { root: true });
      }
    },
    setOpenOrders(state, data = null) {
      let total = 0;
      if (data) total = data;
      else {
        state.Orders.forEach((order) => {
          if (order?.Status?.status === 2) {
            total += 1;
          }
        });
      }
      state.OpenOrders = total;
    },
  },
  actions: {
    updateOpenOrders(context, data = null) {
      context.commit("setOpenOrders", data);
    },
    async updateQuantOfPartByCart(context, products) {
      // Step 1: Create a hash map for the products array
      const productMap = {};
      products.forEach((part) => {
        productMap[part.ordi] = part;
      });

      // Step 2: Update quantities based on OrderLines
      context.state.Cart.OrderLines.forEach((product) => {
        const part = productMap[product.ordi];
        if (part) {
          part.quant = product.quant;
        }
      });

      // Return the updated products array
    },

    async getOrder(context, ordname) {
      store.dispatch("startLoading", null, { root: true });
      try {
        const order = await OrderService.getOrder(ordname);
        context.commit("setOrder", order.data.orders);
      } catch (error) {
        console.log(error);
        store.dispatch("ShowToast", {
          message: "התרחשה שגיאה בהבאת ההזמנה",
          status: "error",
        });
      } finally {
        store.dispatch("stopLoading", null, { root: true });
      }
    },

    async addProductToCart(context, product) {
      var productIndex = context.getters.getPartIndexById(product.ordi);
      if (productIndex !== -1) {
        context.commit("updateProductInCart", { productIndex, product });
      } else {
        context.commit("addProductToCart", product);
      }
      context.commit("setCartTotal");
    },
    async clearCart(context) {
      context.dispatch("Parts/restAllQuantity", context.state.Cart.OrderLines, {
        root: true,
      });
      context.commit("clearCart");
      context.commit("setCartTotal");
    },
    async removeProductFromCart(context, product) {
      var productIndex = context.getters.getPartIndexById(product.ordi);
      context.dispatch("Parts/restQuantityByPart", product, {
        root: true,
      });
      context.commit("removeProductFromCart", productIndex);
      context.commit("setCartTotal");
    },
    //old nirotek code
    async printDelivery(context, docNum) {
      this.dispatch("startLoading", null, { root: true });
      try {
        let userLang = context.rootState.AuthModule.user.language;

        let res =
          userLang === "he"
            ? await OrderService.printDelivery(docNum)
            : await OrderService.printDeliveryEnglish(docNum);

        if (res) {
          context.dispatch(
            "stopLoading",
            { status: "success", message: "הורדה הושלמה" },
            { root: true }
          );
        }

        download(
          res.data,
          `https://app-prod.nirotek.com/netfiles/1450130p1364427430.pdf`,
          res.headers["content-type"]
        );

        return res;
      } catch (err) {
        console.log("error printOrder from Orders.js:", err);
        context.dispatch(
          "stopLoading",
          { status: "error", message: "שגיאה בניסיון הצגת הזמנה" },
          { root: true }
        );
      }
    },
    async printOrder(context, ordName) {
      this.dispatch("startLoading", null, { root: true });
      try {
        let res = await OrderService.printOrder(ordName);
        if (res) {
          context.dispatch(
            "stopLoading",
            { status: "success", message: "מציג הזמנה" },
            { root: true }
          );
        }

        download(
          res.data,
          `https://app-prod.nirotek.com/netfiles/1450130p1364427430.pdf`,
          res.headers["content-type"]
        );

        return res;
      } catch (err) {
        console.log("error printOrder from Orders.js:", err);
        context.dispatch(
          "stopLoading",
          { status: "error", message: "שגיאה בניסיון הצגת הזמנה" },
          { root: true }
        );
      }
    },

    updateCart(context, data) {
      context.commit("setCart", data);
    },
    async getOrdersPagination(context, obj) {
      this.dispatch("startLoading", null, { root: true });
      const ordersa = await OrderService.getOrdersPagination(obj);
      console.log(ordersa)
      store.commit("Orders/orderCount", ordersa.data.count);
      context.dispatch("updateOrders", ordersa.data.orders.rows);
      context.dispatch(
        "stopLoading",
        { status: "error", message: "שגיאה בהבאת נתונים" },
        { root: true }
      );
    },
    updateOrders(context, orders) {
      if (orders && orders.length > 0) context.commit("setOrders", orders);
      // context.commit("setOpenOrders", null);
    },
    async sendOrderToPriority(context, order) {
      this.dispatch("startLoading", null, { root: true });

      try {
        const response = (await OrderService.sendOrderToPriority(order)).data;
        context.dispatch("stopLoading", null, { root: true });
        return response;
      } catch (error) {
        console.log("error updateing order:", error);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: "ארעה שגיאה, מומלץ לרענן את הדף ולנסות שוב",
          },
          { root: true }
        );
        return error;
      }

      // console.log('The Order --->', response);
    },
    async removeOrderLine({ commit }, data) {
      try {
        await OrderService.removeOrderLine(data);
      } catch (err) {
        console.log("error remove line:" + err);
      }
      commit("removeLine", data);
    },
    async newOrder(context, data) {
      this.dispatch("startLoading", null, { root: true });
      try {
        const response = await OrderService.newOrder(data);
        return response.data;
      } catch (error) {
        console.log(error);
        context.dispatch(
          "ShowToast",
          {
            message:
              "אירעה שגיאה בעת יצירת הזמנה חדשה, יש לנסות שנית מאוחר יותר",
            status: "error",
          },
          { root: true }
        );
        return null;
      } finally {
        this.dispatch("stopLoading", null, { root: true });
      }
    },
    async updateOrder(context, data) {
      this.dispatch("startLoading", null, { root: true });

      try {
        console.log("updateOrder");
        console.log(data);
        context.commit("setOrder", data);
        context.commit("setOpenOrders");
        context.dispatch(
          "stopLoading",
          { status: "success", message: "השינויים נשמרו בהצלחה" },
          { root: true }
        );
      } catch (error) {
        console.log("error updateing order:", error);
        context.dispatch(
          "stopLoading",
          { status: "error", message: "ההזמנה נשלחה לביצוע אך לא עודכנה באתר" },
          { root: true }
        );
      }
    },
  },
  getters: {
    getCartTotalPrice(state) {
      let total = 0;
      for (let orderLine of state.Cart.OrderLines) {
        total += orderLine.Part.priceIncludeTax * orderLine.quant;
      }
      return total;
    },
    getCartOrderLinesNumber(state) {
      return state.Cart.OrderLines.length;
    },
    getOrderStatuses(state) {
      let statusList = {};
      for (let order of state.Orders) {
        if (typeof statusList[order.Status.status] === "undefined")
          statusList[order.Status.status] = {
            id: order.Status.status,
            name: order.Status.statusname,
            count: 0,
            enName: order.Status.estatusname,
          };
        statusList[order.Status.status].count++;
      }
      return statusList;
    },
    getPartIndexById: (state) => (ordi) => {
      return state.Cart.OrderLines.findIndex((line) => {
        console.log(line, ordi);
        return line.ordi === ordi;
      });
    },
    //old nirotek code
    getCountOrders(state) {
      return state.numberOfOrders ?? 0;
    },
    getOrders(state) {
      return state.numberOfOrders > 0 ? state.Orders : [];
    },
    getOrder(state) {
      return state.order.rows[0];
    },
    getCart(state) {
      return state.Cart;
    },
    getCartTotal(state) {
      return state.CartTotal;
    },
    getOpenOrders(state) {
      return state.OpenOrders;
    },
  },
};
